import {
  // Load Personal Fund
  START_LOAD_PERSONAL_FUND,
  LOAD_PERSONAL_FUND_SUCCESS,
  LOAD_PERSONAL_FUND_FAILURE,
  INIT_LOAD_PERSONAL_FUND_ACTION_STATUS,

} from './personalFundConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// Load Personal Fund
function startLoadPersonalFund() {
  return {
    type: START_LOAD_PERSONAL_FUND
  };
}

export function loadPersonalFund(userGuid) {
  const requestParams = {
    "user_guid": userGuid
  };

  return function(dispatch) {
    dispatch(startLoadPersonalFund());
    httpClient
      .get(UrlConfig.LOAD_PERSONAL_FUND, requestParams)
      .then(resp => dispatch(loadPersonalFundSuccess(resp)))
      .catch(err => dispatch(loadPersonalFundFailure(err)));
  };
}

function loadPersonalFundSuccess(resp) {
  return {
    type: LOAD_PERSONAL_FUND_SUCCESS,
    payload: resp
  };
}

function loadPersonalFundFailure(err) {
  return {
    type: LOAD_PERSONAL_FUND_FAILURE,
    payload: err
  };
}

export function initLoadPersonalFundActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_FUND_ACTION_STATUS
  };
}