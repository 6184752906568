import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import './InvestorRegister.css';

class InvestorRegisteredHint extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: props.history.location.state.name,
    };
  }

  componentDidMount() {

  }

  render() {
    const {
      name,
    } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>温馨提示</title>
        </Helmet>
        <div className="hintContent">
          尊敬的<span style={{ color: '#ffc000' }}>{ name }</span>，您好。您已经注册成为投资者，无需重新注册。
        </div>
      </div>
    );
  }
}

export default InvestorRegisteredHint;