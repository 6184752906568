
function succRequestHandle(config) {
  return config;
}

function errRequestHandle(error) {
  return error;
}

export default {
  succRequestHandle,
  errRequestHandle,
};
