import {connect} from 'react-redux';
import FundProductList from '../components/FundProductList';

import {
  loadFundProducts,
  initLoadFundProductsActionStatus,
} from '../fundProductActions';

import {
  // Load WX Auth Info
  loadWXAuthInfo,
  initLoadWXAuthInfoActionStatus,
} from '../../home/homeActions';

const mapStateToProps = state => {
  return {
    // Load Fund Products Action Status
    loadFundProductsActionStatus: state.fundProduct.loadFundProductsActionStatus,

    fundProductList: state.fundProduct.fundProductList,
    fundProductDataBlob: state.fundProduct.fundProductDataBlob,

    // Load WX Auth Info
    loadWXAuthInfoActionStatus: state.home.loadWXAuthInfoActionStatus,
    wxAuthInfo: state.home.wxAuthInfo,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Fund Products
    loadFundProducts: () => dispatch(loadFundProducts()),
    initLoadFundProductsActionStatus: () => dispatch(initLoadFundProductsActionStatus()),

    // Load WX Auth Info
    loadWXAuthInfo: (code) => dispatch(loadWXAuthInfo(code)),
    initLoadWXAuthInfoActionStatus: () => dispatch(initLoadWXAuthInfoActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundProductList);