import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { SegmentedControl } from 'antd-mobile';
import PersonalRiskEvaluation from './PersonalRiskEvaluation';
import OrgRiskEvaluation from './OrgRiskEvaluation';
import './RiskEvaluation.css';

class RiskEvaluationDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
    };
  }
  
  componentDidMount() {
    this.props.loadRiskEvaluations();
  }

  segmentedControlOnValueChanged = (value) => {
    if (value === '个人版') {
      this.setState({
        tabIndex: 0
      });
    }

    if (value === '机构版') {
      this.setState({
        tabIndex: 1
      });
    }
  }

  render() {
    const {
      tabIndex,
    } = this.state;

    return (
      <div className="riskEvaluationDashboard">
        <Helmet>
          <meta charSet="utf-8" />
          <title>基金投资者风险测评问卷</title>
        </Helmet>
        <div className="riskEvaluationSegmentedControl">
          <SegmentedControl 
            selectedIndex={tabIndex} 
            onValueChange={this.segmentedControlOnValueChanged}
            tintColor={'#1970b6'}
            values={['个人版', '机构版']} />
        </div>
        <p className='riskTip'>
          风险提示：基金投资需承担各类风险，本金可能遭受失。同时，还要考虑市场风险、信用风险、流动风险、操作风险等各类投资风险。
          您在基金认购过程中应当注意核对自己的风险识别和风险承受能力，选择与自己风险识别能力和风险承受能力相匹配的私募基金。 
        </p>
        <p className='investorCategory'>
        出现以下任一情形，风险评估自动评为C1型-能力最低类别投资者： <br/>
        A 不具有完全民事行为能力  <br/>
        B 不具备证券期货投资知识或者金融投资经验  <br/>
        C 仅追求稳定收益，不愿本金损失  <br/>
        </p>
        <div className='scoreEvaluationTable'>
          <span>得分评估表</span>
          <table>
            <thead>
              <tr>
                <td>20-30</td>
                <td>31-50</td>
                <td>51-70</td>
                <td>71-90</td>
                <td>90-100</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>C1</td>
                <td>C2</td>
                <td>C3</td>
                <td>C4</td>
                <td>C5</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 个人版 */}
        {
          tabIndex === 0 && 
          <PersonalRiskEvaluation {...this.props} />
        }
        {/* 机构版 */}
        {
          tabIndex === 1 && 
          <OrgRiskEvaluation {...this.props} />
        }
      </div>
    );
  }
}

export default RiskEvaluationDashboard;