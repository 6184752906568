import React, { Component } from 'react';
import { ActivityIndicator } from 'antd-mobile';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

class TradeRecordTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      personalFund: props.history.location.state.personalFund,
    };
  }

  render() {
    const {
      personalTradeRecordList,
      className = 'grid-wrapper',
      loadPersonalTradeRecordActionStatus
    } = this.props;

    const isLoading = loadPersonalTradeRecordActionStatus === ACTION_STATUS.LOGINING;
    if (loadPersonalTradeRecordActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadPersonalTradeRecordActionStatus();
    }

    const {
      personalFund,
    } = this.state;

    return (
      <div className={`${className}`}>
        {isLoading ? (
            <ActivityIndicator
              toast
              text="Loading..."
              animating={isLoading}
            />
          ) : (
          <table>
            <thead>
              <tr>
                <td>日期</td>
                <td>类型</td>
                <td>总金额(万)</td>
                <td>净金额(万)</td>
                <td>总费用(万)</td>
                <td>交易费(万)</td>
                <td>业绩报酬(万)</td>
                <td>份额</td>
                <td>净值</td>
              </tr>
            </thead>
            <tbody>
              {personalTradeRecordList.map((tradeRecord, i) => (
                    <tr key={i}>
                      <td>{tradeRecord.dateLabel}</td>
                      <td>{tradeRecord.type}</td>
                      <td>{tradeRecord.totalAmount}</td>
                      <td>{tradeRecord.netAmount}</td>
                      <td>{tradeRecord.totalFee}</td>
                      <td>{tradeRecord.tradeFee}</td>
                      <td>{tradeRecord.perfFee}</td>
                      <td>{this.props.history.location.state.personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : tradeRecord.share}</td>
                      <td>{this.props.history.location.state.personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : tradeRecord.price}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default TradeRecordTable;