import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

import {
    // change password
    START_WX_CHANGE_PASSWORD,
    WX_CHANGE_PASSWORD_SUCCESS,
    WX_CHANGE_PASSWORD_FAILURE,
    INIT_WX_CHANGE_PASSWORD_ACTION_STATUS,

} from './changePasswordConstants';



// change password
function startWxChangePassword() {
    return {
      type: START_WX_CHANGE_PASSWORD,
    };
}
export function wxChangePassword(params) {
    return function(dispatch) {
        dispatch(startWxChangePassword());
        httpClient
            .post(UrlConfig.WX_CHANGE_PASSWORD, params)
            .then(resp => dispatch(wxChangePasswordSuccess(resp)))
            .catch(err => dispatch(wxChangePasswordFailure(err)));
    };
}
function wxChangePasswordSuccess(resp) {
    return {
        type: WX_CHANGE_PASSWORD_SUCCESS,
        payload: resp,
    };
}
function wxChangePasswordFailure(err) {
    return {
        type: WX_CHANGE_PASSWORD_FAILURE,
        payload: err,
    };
}
export function initWxChangePasswordActionStatus() {
    return {
        type: INIT_WX_CHANGE_PASSWORD_ACTION_STATUS,
    };
}



