import {connect} from 'react-redux';
import FundDetail from '../components/FundDetail';


import {
  // Load Fund Basic Info 
  loadFundBasicInfo,
  initLoadFundBasicInfoActionStatus,

  // Load Fund Values
  loadFundValues,
  initLoadFundValuesActionStatus,

} from '../fundDetailActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    fundValueGridColumns: state.fundDetail.fundValueGridColumns,

    // Load Fund Basic Info Action Status
    loadFundBasicInfoActionStatus: state.fundDetail.loadFundBasicInfoActionStatus,
    fundBasicInfo: state.fundDetail.fundBasicInfo,

    // Load Fund Values Action Status
    loadFundValuesActionStatus: state.fundDetail.loadFundValuesActionStatus,
    fundValueList: state.fundDetail.fundValueList,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Fund Basic Info 
    loadFundBasicInfo: (fundGuid) => dispatch(loadFundBasicInfo(fundGuid)),
    initLoadFundBasicInfoActionStatus: () => dispatch(initLoadFundBasicInfoActionStatus()),

    // Load Fund Values
    loadFundValues: (entityGuid) => dispatch(loadFundValues(entityGuid)),
    initLoadFundValuesActionStatus: () => dispatch(initLoadFundValuesActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundDetail);