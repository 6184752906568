import {
  // Send Captcha
  START_SEND_CAPTCHA,
  SEND_CAPTCHA_SUCCESS,
  SEND_CAPTCHA_FAILURE,
  INIT_SEND_CAPTCHA_ACTION_STATUS,

  // Auth Captcha
  START_AUTH_CAPTCHA,
  AUTH_CAPTCHA_SUCCESS,
  AUTH_CAPTCHA_FAILURE,
  INIT_AUTH_CAPTCHA_ACTION_STATUS,

  // auth password
  START_WX_CHECK_PASSWORD,
  WX_CHECK_PASSWORD_SUCCESS,
  WX_CHECK_PASSWORD_FAILURE,
  INIT_WX_CHECK_PASSWORD_ACTION_STATUS,




  ///////////
  START_IS_ERROR_USER,
  IS_ERROR_USER_SUCCESS,
  IS_ERROR_USER_FAILURE,
  INIT_IS_ERROR_USER_ACTION_STATUS,

} from './authQueryConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

// Send Captcha
function startSendCaptcha() {
  return {
    type: START_SEND_CAPTCHA
  };
}

export function sendCaptcha(openId) {
  const requestParams = {
    openId,
  };

  return function(dispatch) {
    dispatch(startSendCaptcha());
    httpClient
      .post(UrlConfig.SEND_WX_AUTH_QUERY_CAPTCHA, requestParams)
      .then(resp => dispatch(sendCaptchaSuccess(resp)))
      .catch(err => dispatch(sendCaptchaFailure(err)));
  };
}

function sendCaptchaSuccess(resp) {
  return {
    type: SEND_CAPTCHA_SUCCESS,
    payload: resp
  };
}

function sendCaptchaFailure(err) {
  return {
    type: SEND_CAPTCHA_FAILURE,
    payload: err
  };
}

export function initSendCaptchaActionStatus() {
  return {
    type: INIT_SEND_CAPTCHA_ACTION_STATUS
  };
}

// Auth Captcha
function startAuthCaptcha() {
  return {
    type: START_AUTH_CAPTCHA
  };
}

export function authCaptcha(openId, phoneNo, authToken, captcha) {
  const requestParams = {
    openId, 
    phoneNo, 
    authToken,
    captcha
  };

  return function(dispatch) {
    dispatch(startAuthCaptcha());
    httpClient
      .post(UrlConfig.AUTH_USER_ASSET_QUERY, requestParams)
      .then(resp => dispatch(authCaptchaSuccess(resp)))
      .catch(err => dispatch(authCaptchaFailure(err)));
  };
}

function authCaptchaSuccess(resp) {
  return {
    type: AUTH_CAPTCHA_SUCCESS,
    payload: resp
  };
}

function authCaptchaFailure(err) {
  return {
    type: AUTH_CAPTCHA_FAILURE,
    payload: err
  };
}

export function initAuthCaptchaActionStatus() {
  return {
    type: INIT_AUTH_CAPTCHA_ACTION_STATUS
  };
}


  // Auth Password
export function wxCheckPassword(openId, password) {
  const requestParams = {
    openId, 
    password,
  };

  return function(dispatch) {
    dispatch(startWxCheckPassword());
    httpClient
      .get(UrlConfig.WX_CHECK_PASSWORD, requestParams)
      .then(resp => dispatch(wxCheckPasswordSuccess(resp)))
      .catch(err => dispatch(wxCheckPasswordFailure(err)));
  };
}
function startWxCheckPassword() {
  return {
    type: START_WX_CHECK_PASSWORD,
  };
}
function wxCheckPasswordSuccess(resp) {
  return {
    type: WX_CHECK_PASSWORD_SUCCESS,
    payload: resp
  };
}
function wxCheckPasswordFailure(err) {
  return {
    type: WX_CHECK_PASSWORD_FAILURE,
    payload: err
  };
}
export function initWxCheckPasswordActionStatus() {
  return {
    type: INIT_WX_CHECK_PASSWORD_ACTION_STATUS,
  };
}



// isErrUser
function startIsErrUser() {
  return {
    type: START_IS_ERROR_USER,
  };
}
export function isErrUser(openId) {
  const requestParams = {
    openId, 
  };
  return function(dispatch) {
    dispatch(startIsErrUser());
    httpClient
      .get(UrlConfig.IS_ERROR_USER, requestParams)
      .then(resp => dispatch(isErrUserSuccess(resp)))
      .catch(err => dispatch(isErrUserFailure(err)));
  };
}
function isErrUserSuccess(resp) {
  return {
    type: IS_ERROR_USER_SUCCESS,
    payload: resp
  };
}
function isErrUserFailure(err) {
  return {
    type: IS_ERROR_USER_FAILURE,
    payload: err
  };
}
export function initIsErrUserActionStatus() {
  return {
    type: INIT_IS_ERROR_USER_ACTION_STATUS,
  };
}
