import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { Button, InputItem, Toast } from 'antd-mobile';

import CountDownButton from '../../../components/CountDownButton';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import './AuthQuery.css';


class ChangePasswordDashboard extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          openId: props.history.location.state.openId,
          userGuid: props.history.location.state.userGuid,
          mobileNo: props.history.location.state.mobileNo,
          userName: props.history.location.state.userName,
        };
    }


    componentDidUpdate() {
        const {
             sendCaptchaActionStatus,  // Send Captcha
             wxChangePasswordActionStatus,  // change password
             wxChangePasswordActionResp,  // change password
        } = this.props;

        // Send Captcha
        if (sendCaptchaActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initSendCaptchaActionStatus();
        }
        if (sendCaptchaActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSendCaptchaActionStatus();
        }

        // change password
        if(wxChangePasswordActionStatus === ACTION_STATUS.SUCCESS) {
            Toast.success('密码修改成功', 2);
            this.props.initWxChangePasswordActionStatus();
            this.goToCheckPassword();
        }
        if(wxChangePasswordActionStatus === ACTION_STATUS.ERROR) {
            let err = '密码修改失败';
            if(wxChangePasswordActionResp !== undefined && wxChangePasswordActionResp.respMessage !== undefined) {
               err = wxChangePasswordActionResp.respMessage;
            }
            Toast.fail(err, 2);
            this.props.initWxChangePasswordActionStatus();
        }


    }


    onInputChange = ({name, value}) => {
        this.setState({
          ... this.state,
          [name]: value,
        });
    }


    countDownBtnOnClicked = () => {
        this.countDownButton.startCountDown();  // 倒计时
        const openId = this.state.openId;
        this.props.sendCaptcha(openId);
    }


    onRef = (ref) => {
        this.countDownButton = ref;
    }


    confirmBtnOnClick = () => {
        const authToken = this.props.authToken;
        const {
            openId,
            mobileNo,
            captcha,
            newPassword1,
            newPassword2,
        } = this.state;
        const params = {
            openId: openId,
            newPassword: newPassword1,
            phoneNo: mobileNo,
            authToken: authToken,
            captcha: captcha,
        };
        if(!!!authToken) {
            Toast.fail('请先获取验证码', 2);
            return false;
        }
        if(!!!captcha) {
            Toast.fail('请输入验证码', 2);
            return false;
        }
        if(!!!newPassword1) {
            Toast.fail('请输入新密码', 2);
            return false;
        }
        let regex = /^[\da-zA-Z]{6,20}$/;
        if(!regex.test(newPassword1)) {
            Toast.fail('密码长度须在6-20位之间，且只能包括字母和数字', 2);
            return false;
        }
        if(newPassword1 !== newPassword2) {
            Toast.fail('两次密码输入须一致', 2);
            return false;
        }
        this.props.wxChangePassword(params);
    }


    goToCheckPassword = () => {
        const userGuid = this.state.userGuid;
        const openId = this.state.openId;
        const userName = this.state.userName;
        const mobileNo = this.state.mobileNo;
        this.props.history.push({
            pathname: '/home/auth-query', 
            state: {
                openId,
                userGuid,
                userName,
                mobileNo,
                authTabIdx: 1,
            }
        });
    }



    render() {
        const {
            mobileNo,
            captcha,
            newPassword1,
            newPassword2,
          } = this.state;

          let confirmBtnDisabled = false;
          confirmBtnDisabled = confirmBtnDisabled || !!!captcha || !!!newPassword1 || !!!newPassword2;


          
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>修改密码</title>
                </Helmet>
                <div className="authQueryBox">
                    <div className="countDownBox">
                        <InputItem className="phoneNoInputItem" disabled={true} value={ mobileNo } />
                        <CountDownButton onRef={this.onRef}  buttonOnClick={this.countDownBtnOnClicked}/>
                    </div>
                    <InputItem 
                        value={ captcha }
                        onChange={(v) => { this.onInputChange({ name: 'captcha', value: v }) }}
                        type='number'
                        maxLength={6}
                        className="authCodeInputItem" 
                        placeholder="请输入验证码" />
                    <InputItem 
                        value={ newPassword1 }
                        onChange={(v) => { this.onInputChange({ name: 'newPassword1', value: v }) }}
                        type='password'
                        maxLength={20}
                        className="authCodeInputItem" 
                        placeholder="请输入新密码" />
                    <InputItem 
                        value={ newPassword2 }
                        onChange={(v) => { this.onInputChange({ name: 'newPassword2', value: v }) }}
                        type='password'
                        maxLength={20}
                        className="authCodeInputItem" 
                        placeholder="请再次输入新密码" />
                    <Button 
                        style={{ backgroundColor: '#1970b6', color: 'white' }}
                        className="confirmBtn"
                        disabled={confirmBtnDisabled}
                        onClick={this.confirmBtnOnClick}>确定</Button>
                    <div>密码长度6-20位，只能包括字母和数字。两次密码输入须一致。</div>
                    <div><a href='#' onClick={()=>this.goToCheckPassword()}>返回密码验证界面</a></div>
                </div>
            </div>
            
        );
    }
}

export default ChangePasswordDashboard;