
const APPOINTMENT_TYPE_CODE = {
  PURCHASE: '0',
  REDEMPTION: '1',
};

const APPOINTMENT_TYPE_LABEL = {
  PURCHASE: '申购',
  REDEMPTION: '赎回',
};

export const APPOINTMENT_TYPE_MAP = [
  // 申购
  {
    code: APPOINTMENT_TYPE_CODE.PURCHASE,
    label: APPOINTMENT_TYPE_LABEL.PURCHASE
  },
  // 赎回
  {
    code: APPOINTMENT_TYPE_CODE.REDEMPTION,
    label: APPOINTMENT_TYPE_LABEL.REDEMPTION
  },
];