import {connect} from 'react-redux';
import AppointmentBlank from '../components/AppointmentBlank';

import {
  // Load WX Auth Info
  loadWXAuthInfo,
  initLoadWXAuthInfoActionStatus,

  // Load UserInfo By OpenId
  loadUserInfo,
  initLoadUserInfoActionStatus,
} from '../../home/homeActions';

const mapStateToProps = state => {
  return {
    // WX Auth Info
    loadWXAuthInfoActionStatus: state.home.loadWXAuthInfoActionStatus,
    wxAuthInfo: state.home.wxAuthInfo,

    // UserInfo By OpenId
    loadUserInfoActionStatus: state.home.loadUserInfoActionStatus,
    loadUserInfoResult: state.home.loadUserInfoResult,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load WX Auth Info
    loadWXAuthInfo: (code) => dispatch(loadWXAuthInfo(code)),
    initLoadWXAuthInfoActionStatus: () => dispatch(initLoadWXAuthInfoActionStatus()),

    // Load UserInfo By OpenId
    loadUserInfo: (openId) => dispatch(loadUserInfo(openId)),
    initLoadUserInfoActionStatus: () => dispatch(initLoadUserInfoActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentBlank);