import React, {Component} from 'react';
import { Button, Toast, Modal } from 'antd-mobile';
import Radio from 'antd/es/radio';
import Form from 'antd/es/form';
import Checkbox from 'antd/es/checkbox';

import './RiskEvaluation.css';

class OrgRiskEvaluation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      riskOptions: [],
      totalScore: 0,
      scoreResult: 'C1',
      modelContent: '',
      modalVisible: false,
    };
  }
  
  componentDidMount() {
    
  }

  componentDidUpdate() {

  }

  // Risk Evaluation Radio Changed
  riskEvaluationRadioOnChanged = (data, e) => {
    const selectedValue = e.target.value;
    console.log('text ', selectedValue);

    let riskOptions = this.state.riskOptions;

    const filterRiskOption = riskOptions.filter(option => selectedValue.questionGuid === option.questionGuid);

    if (filterRiskOption.length !== 0) {
      let i = riskOptions.indexOf(filterRiskOption[0]);
      riskOptions.splice(i, 1);
    }
    
    riskOptions.push(selectedValue);

    this.setState({
      riskOptions,
    });
  };

  // Risk Evaluation CheckBox Changed
  riskEvaluationCheckBoxOnChanged = (data, e) => {
    let riskQuestion = data;
    let riskOptions = this.state.riskOptions;
    const newRiskOptions = [];
    
    const otherRiskOptions = riskOptions.filter(riskOption => riskOption.questionGuid !== riskQuestion.guid);

    newRiskOptions.push(...otherRiskOptions);
    newRiskOptions.push(...e);

    console.log(newRiskOptions);

    this.setState({
      riskOptions: newRiskOptions,
    });
  }

  // 提交评测结果
  confirmBtnOnClick = () => {
    const riskOptions = this.state.riskOptions;
    const scoreArray = riskOptions.map(option => option.score);

    const isC1OptionArray = riskOptions.filter(option => option.isC1);
    
    const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

    let totalScore = 0;
    if (riskOptions.length !== 0) {
      totalScore = scoreArray.reduce(sumReducer);
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        let scoreResult = 'C1';
        let modelContent = `您当前得分为${totalScore}分, `;
        
        if (isC1OptionArray.length === 0) {
          if (totalScore < 30) {
            scoreResult = 'C1';
            modelContent = modelContent + `投资者类型为${scoreResult}, 您当前不能注册`;
          } 
      
          if (totalScore >= 30 && totalScore < 50) {
            scoreResult = 'C2';
            modelContent = modelContent + `投资者类型为${scoreResult}, 您当前不能注册`;
          } 
      
          if (totalScore >= 50 && totalScore < 70) {
            scoreResult = 'C3';
            modelContent = modelContent + `投资者类型为${scoreResult}, 您当前不能注册`;
          } 
      
          if (totalScore >= 70 && totalScore < 90) {
            scoreResult = 'C4';
            modelContent = modelContent + `投资者类型为${scoreResult}, 点击 知道了 按钮进入注册页面`;
          } 
      
          if (totalScore >= 90 && totalScore <= 100) {
            scoreResult = 'C5';
            modelContent = modelContent + `投资者类型为${scoreResult}, 点击 知道了 按钮进入注册页面`;
          } 
        } else {
          scoreResult = 'C1';
          modelContent = `因为您的答案中包含符合C1型-能力最低类别投资者的情形, 因此投资者类型直接判定为${scoreResult}, 您当前不能注册`;
        }        
        
        this.setState({
          totalScore,
          scoreResult,
          modelContent,
          modalVisible: true,
        });

      } else {
        Toast.fail("您还有评测题目未选择", 2);
      }
    });
  }

  modalOnClose = () => {
    const scoreResult = this.state.scoreResult;

    if (scoreResult === 'C4' || scoreResult === 'C5') {
      this.setState({
        riskOptions: [],
        totalScore: 0,
        scoreResult: 'C1',
        modelContent: '',
        modalVisible: false,
      });

      this.props.history.replace({
        pathname: '/home/investor-register-blank',
      });
    } else {
      this.setState({
        modalVisible: false,
      });
    } 
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      // Load Risk Evaluations
      loadRiskEvaluationsActionStatus,
      orgRiskEvaluations,
    } = this.props;

    const {
      modalVisible,
      modelContent,
    } = this.state;

    return (
      <div>
        {
          orgRiskEvaluations.map((value, index) => {
            return (
              <div className='riskEvaluationQuestionBox' key={value.guid}>
                {value.content}
                {
                  value.questionType === '1' &&
                  <Form.Item key={value.guid}>
                    {getFieldDecorator(`${value.guid}`, {
                      rules: [
                        { required: true, message: '您必须选择一个选项' },
                      ],
                    })(
                      <Radio.Group onChange={this.riskEvaluationRadioOnChanged.bind(this, value)}>
                        {
                          value.riskEvaluationOptions.map((valueOption, indexOption) => {
                            return (
                              <Radio value={valueOption} key={valueOption.guid}>{ `${valueOption.content} (` + `${valueOption.score})` + `${valueOption.isC1 ? '【直接判定C1】' : ''}` }</Radio>
                            );
                          })
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                }
                {
                  value.questionType === '2' &&
                  <Form.Item key={value.guid}>
                    {getFieldDecorator(`${value.guid}`, {
                      rules: [
                        { required: true, message: '您必须选择至少一个选项' },
                      ],
                    })(
                      <div>
                        <Checkbox.Group onChange={this.riskEvaluationCheckBoxOnChanged.bind(this, value)}>
                          {
                            value.riskEvaluationOptions.map((valueOption, indexOption) => {
                              return (
                                <Checkbox style={{ marginLeft: 0 }} value={valueOption} key={valueOption.guid}>{ `${valueOption.content} (` + `${valueOption.score})` }</Checkbox>
                              );
                            })
                          }
                        </Checkbox.Group>
                        <span style={{ width: '100%' }}>( 注：本题可多选，但评分以其中最高分值选项为准。)</span>
                      </div>
                    )}
                  </Form.Item>
                }
              </div>
            );
          })
        }
        <div className='personalRiskBtnBox'>
          <Button 
            type="primary" 
            inline 
            size="small" 
            style={{ width: 100 }}
            onClick={this.confirmBtnOnClick}>确定</Button>
        </div>
        <Modal
          visible={modalVisible}
          transparent
          maskClosable={false}
          title="风险评测问卷结果"
          onClose={this.modalOnClose}
          footer={[{ text: '知道了...', onPress: this.modalOnClose }]}
        >
          {modelContent}
        </Modal>
      </div>
    );
  }
}

const OrgRiskEvaluationForm = Form.create({ name: 'org-risk-evaluation' })(OrgRiskEvaluation);

export default OrgRiskEvaluationForm;