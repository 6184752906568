import {
 // Make Appointment
  START_MAKE_APPOINTMENT,
  MAKE_APPOINTMENT_SUCCESS,
  MAKE_APPOINTMENT_FAILURE,
  INIT_MAKE_APPOINTMENT_ACTION_STATUS,

} from './reservationConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// Make Appointment
function startMakeAppointment() {
  return {
    type: START_MAKE_APPOINTMENT
  };
}

export function makeAppointment(appointment) {
  return function(dispatch) {
    dispatch(startMakeAppointment());
    httpClient
      .post(UrlConfig.MAKE_APPOINTMENT, appointment)
      .then(resp => dispatch(makeAppointmentSuccess(resp)))
      .catch(err => dispatch(makeAppointmentFailure(err)));
  };
}

function makeAppointmentSuccess(resp) {
  return {
    type: MAKE_APPOINTMENT_SUCCESS,
    payload: resp
  };
}

function makeAppointmentFailure(err) {
  return {
    type: MAKE_APPOINTMENT_FAILURE,
    payload: err
  };
}

export function initMakeAppointmentActionStatus() {
  return {
    type: INIT_MAKE_APPOINTMENT_ACTION_STATUS
  };
}