const BaseUrlConfig = {
  dev: {
    api: {
      REST_URL: 'http://localhost:9000/partnership',
      // REST_URL: 'http://localhost:8000/partnership',
      API_VERSION: '/api/v1',
    },
  },
  qa: {
    api: {
      REST_URL: 'https://wxqa.pinpointfund.cn/partnership',
      API_VERSION: '/api/v1',
    }
  },
  prod: {
    api: {
      // REST_URL: 'http://220.241.80.226:80/partnership',  // main - hk
      // REST_URL: 'http://118.140.161.119:80/partnership',  // temp - hk
      REST_URL: 'https://wx.pinpointfund.cn/partnership',  // sh
      API_VERSION: '/api/v1',
    }
  }
}[process.env.REACT_APP_ENV || 'dev'];

export default BaseUrlConfig;
