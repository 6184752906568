import React from 'react';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import Select from 'antd/es/select';

import {
  // Make Appointment
  START_MAKE_APPOINTMENT,
  MAKE_APPOINTMENT_SUCCESS,
  MAKE_APPOINTMENT_FAILURE,
  INIT_MAKE_APPOINTMENT_ACTION_STATUS,
 
 } from './appointmentConstants';

 import {
  // Load Fund Products
  LOAD_FUND_PRODUCTS_SUCCESS,
 } from '../fundProduct/fundProductConstants';

 const Option = Select.Option;

const initialState = {
  // Make Appointment
  makeAppointmentActionStatus: ACTION_STATUS.READY,

  // Fund Select Options
  fundOptions: [],
}

// Make Appointment
function startMakeAppointment(state) {
  return {
    ...state,
    makeAppointmentActionStatus: ACTION_STATUS.LOGINING,
  };
}

function makeAppointmentSuccess(state, resp) {
  return {
    ...state,
    makeAppointmentActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function makeAppointmentFailure(state, err) {
  return {
    ...state,
    makeAppointmentActionStatus: ACTION_STATUS.ERROR,
  };
}

function initMakeAppointmentActionStatus(state) {
  return {
    ...state,
    makeAppointmentActionStatus: ACTION_STATUS.READY,
  };
}

// Load Fund Products
function loadFundProductsSuccess(state, resp) {
  const fundProductList = resp.resultData;
  const fundOptions = fundProductList.map(fund => <Option key={fund.fundGuid}>{fund.fundName}</Option>);

  return {
    ...state,
    fundOptions,
  };
}

export default createReducer(initialState, {
  // Make Appointment
  [START_MAKE_APPOINTMENT]: startMakeAppointment,
  [MAKE_APPOINTMENT_SUCCESS]: makeAppointmentSuccess,
  [MAKE_APPOINTMENT_FAILURE]: makeAppointmentFailure,
  [INIT_MAKE_APPOINTMENT_ACTION_STATUS]: initMakeAppointmentActionStatus,

  // Load Fund Products
  [LOAD_FUND_PRODUCTS_SUCCESS]: loadFundProductsSuccess,
});