import {connect} from 'react-redux';
import PersonalFundDetail from '../components/PersonalFundDetail';

import {
  // Load Personal Fund Basic Info
  loadPersonalFundBasicInfo,
  initLoadPersonalFundBasicInfoActionStatus,

  // Load Personal Fund Values
  loadPersonalFundValues,
  initLoadPersonalFundValuesActionStatus,

  // Load Personal Trade Record
  loadPersonalTradeRecord,
  initLoadPersonalTradeRecordActionStatus,

} from '../personalFundDetailActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    fundValueGridColumns: state.personalFundDetail.fundValueGridColumns,
    tradeRecordGridColumns: state.personalFundDetail.tradeRecordGridColumns,

    // Load Personal Fund Basic Info
    loadPersonalFundBasicInfoActionStatus: state.personalFundDetail.loadPersonalFundBasicInfoActionStatus,
    personalFundBasicInfo: state.personalFundDetail.personalFundBasicInfo,

    // Load Personal Fund Values
    loadPersonalFundValuesActionStatus: state.personalFundDetail.loadPersonalFundValuesActionStatus,
    personalFundValueList: state.personalFundDetail.personalFundValueList,

    // Load Personal Trade Record
    loadPersonalTradeRecordActionStatus: state.personalFundDetail.loadPersonalTradeRecordActionStatus,
    personalTradeRecordList: state.personalFundDetail.personalTradeRecordList,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Personal Fund Basic Info
    loadPersonalFundBasicInfo: (fundGuid) => dispatch(loadPersonalFundBasicInfo(fundGuid)),
    initLoadPersonalFundBasicInfoActionStatus: () => dispatch(initLoadPersonalFundBasicInfoActionStatus()),

    // Load Personal Fund Values
    loadPersonalFundValues: (param) => dispatch(loadPersonalFundValues(param)),
    initLoadPersonalFundValuesActionStatus: () => dispatch(initLoadPersonalFundValuesActionStatus()),

    // Load Personal Trade Record
    loadPersonalTradeRecord: (investorGuid, entityGuid) => dispatch(loadPersonalTradeRecord(investorGuid, entityGuid)),
    initLoadPersonalTradeRecordActionStatus: () => dispatch(initLoadPersonalTradeRecordActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalFundDetail);