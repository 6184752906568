

const fundValueGridColumns = [
  {
    field: 'priceDateLabel',
    headerName: '日期',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'price',
    headerName: '净值',
    cellClass: 'non-number',
    width: 90,
  },
  {
    field: 'mtdReturn',
    headerName: '月涨幅%',
    cellClass: 'non-number',
    width: 110,
  },
  {
    field: 'ytdReturn',
    headerName: '年涨幅%',
    cellClass: 'non-number',
    width: 110,
  },
  {
    field: 'itdReturn',
    headerName: '累计涨幅%',
    cellClass: 'non-number',
    width: 110,
  },
];

const tradeRecordGridColumns = [
  {
    field: 'dateLabel',
    headerName: '日期',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'type',
    headerName: '类型',
    cellClass: 'non-number',
    width: 70,
  },
  {
    field: 'amount',
    headerName: '金额(万)',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'share',
    headerName: '份额',
    cellClass: 'non-number',
    width: 110,
  },
  {
    field: 'price',
    headerName: '净值',
    cellClass: 'non-number',
    width: 100,
  },
];

const GridColumnMap = {
  fundValueGridColumns,
  tradeRecordGridColumns,
};

export default GridColumnMap;