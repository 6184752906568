import {
  // Load WX Auth Info
  START_LOAD_WX_AUTH_INFO,
  LOAD_WX_AUTH_INFO_SUCCESS,
  LOAD_WX_AUTH_INFO_FAILURE,
  INIT_LOAD_WX_AUTH_INFO_ACTION_STATUS,

  // Load UserInfo By OpenId
  START_LOAD_USERINFO,
  LOAD_USERINFO_SUCCESS,
  LOAD_USERINFO_FAILURE,
  INIT_LOAD_USERINFO_ACTION_STATUS,

  // Auth query blank failed
  SET_AUTH_QUERY_BLANK_FAILED,
  RESET_AUTH_QUERY_BLANK_STATUS,

} from './homeConstants';

import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';


const initialState = {
  // Load WX Auth Info
  loadWXAuthInfoActionStatus: ACTION_STATUS.READY,
  wxAuthInfo: null,

  // Load UserInfo By OpenId
  loadUserInfoActionStatus: ACTION_STATUS.READY,
  loadUserInfoResult: null,
}

// Load WX Auth Info
function startLoadWXAuthInfo(state) {
  return {
    ...state,
    loadWXAuthInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadWXAuthInfoSuccess(state, resp) {
  return {
    ...state,
    loadWXAuthInfoActionStatus: ACTION_STATUS.SUCCESS,
    wxAuthInfo: JSON.parse(resp.resultData),
  };
}

function loadWXAuthInfoFailure(state, err) {
  return {
    ...state,
    loadWXAuthInfoActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadWXAuthInfoActionStatus(state) {
  return {
    ...state,
    loadWXAuthInfoActionStatus: ACTION_STATUS.READY,
  };
}

// Load UserInfo By OpenId
function startLoadUserInfo(state) {
  return {
    ...state,
    loadUserInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadUserInfoSuccess(state, resp) {
  return {
    ...state,
    loadUserInfoActionStatus: ACTION_STATUS.SUCCESS,
    loadUserInfoResult: resp,
  };
}

function loadUserInfoFailure(state, err) {
  return {
    ...state,
    loadUserInfoActionStatus: ACTION_STATUS.ERROR,
    loadUserInfoResult: err,
  };
}

function initLoadUserInfoActionStatus(state) {
  return {
    ...state,
    loadUserInfoActionStatus: ACTION_STATUS.READY,
  };
}


// Auth query blank failed
function setAuthQueryBlankFailed(state, errMsg) {
  return {
    ...state,
    authQueryBlankActionStatus: ACTION_STATUS.ERROR,
    authQueryBlankActionResp: errMsg,
  };
}
function resetAuthQueryBlankStatus(state) {
  return {
    ...state,
    authQueryBlankActionStatus: ACTION_STATUS.READY,
  };
}


export default createReducer(initialState, {
  // Load WX Auth Info
  [START_LOAD_WX_AUTH_INFO]: startLoadWXAuthInfo,
  [LOAD_WX_AUTH_INFO_SUCCESS]: loadWXAuthInfoSuccess,
  [LOAD_WX_AUTH_INFO_FAILURE]: loadWXAuthInfoFailure,
  [INIT_LOAD_WX_AUTH_INFO_ACTION_STATUS]: initLoadWXAuthInfoActionStatus,

  // Load UserInfo By OpenId
  [START_LOAD_USERINFO]: startLoadUserInfo,
  [LOAD_USERINFO_SUCCESS]: loadUserInfoSuccess,
  [LOAD_USERINFO_FAILURE]: loadUserInfoFailure,
  [INIT_LOAD_USERINFO_ACTION_STATUS]: initLoadUserInfoActionStatus,

  // Auth query blank failed
  [SET_AUTH_QUERY_BLANK_FAILED]: setAuthQueryBlankFailed,
  [RESET_AUTH_QUERY_BLANK_STATUS]: resetAuthQueryBlankStatus,

});
