import { connect } from 'react-redux';

import {
  // Load WX Auth Info
  loadWXAuthInfo,
  initLoadWXAuthInfoActionStatus,

  // Load UserInfo By OpenId
  loadUserInfo,
  initLoadUserInfoActionStatus,
} from '../homeActions';

import Home from '../components/Home';

function mapStateToProps(state) {
  return {
    // Load WX Auth Info
    loadWXAuthInfoActionStatus: state.home.loadWXAuthInfoActionStatus,
    wxAuthInfo: state.home.wxAuthInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // Load WX Auth Info
    loadWXAuthInfo: (code) => dispatch(loadWXAuthInfo(code)),
    initLoadWXAuthInfoActionStatus: () => dispatch(initLoadWXAuthInfoActionStatus()),

    // Load UserInfo By OpenId
    loadUserInfo: (openId) => dispatch(loadUserInfo(openId)),
    initLoadUserInfoActionStatus: () => dispatch(initLoadUserInfoActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
