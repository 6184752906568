import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import FundProductContainer from '../../fundProduct/containers/FundProductContainer';
import FundProductBlankContainer from '../../fundProduct/containers/FundProductBlankContainer';
import FundDetailContainer from '../../fundDetail/containers/FundDetailContainer';

import AuthQueryContainer from '../../authQuery/containers/AuthQueryContainer';
import AuthQueryBlankContainer from '../../authQuery/containers/AuthQueryBlankContainer';

import PersonalFundContainer from '../../personalFund/containers/PersonalFundContainer';
import PersonalFundDetailContainer from '../../personalFundDetail/containers/PersonalFundDetailContainer';

import InvestorRegisterContainer from '../../investorRegister/containers/InvestorRegisterContainer';
import InvestorRegisterBlankContainer from '../../investorRegister/containers/InvestorRegisterBlankContainer';
import InvestorRegisteredHintContainer from '../../investorRegister/containers/InvestorRegisteredHintContainer';
import RiskEvaluationDashboardContainer from '../../investorRegister/containers/RiskEvaluationDashboardContainer';

import AppointmentContainer from '../../appointment/containers/AppointmentContainer';
import AppointmentBlankContainer from '../../appointment/containers/AppointmentBlankContainer';

import ReservationContainer from '../../reservation/containers/ReservationContainer';
import ServerDownNoticeContainer from '../../systemNotice/containers/ServerDownNoticeContainer';

import ChangePasswordContainer from '../../authQuery/containers/ChangePasswordContainer';

class Home extends Component {

  componentDidMount () {
    
  }

  render() {

    return (
      <div className="contentBox">
        <Switch>
          <Route path="/home/fund" component={FundProductContainer} />
          <Route path="/home/fund-blank" component={FundProductBlankContainer} />
          <Route path="/home/fund-detail" component={FundDetailContainer} />

          <Route path="/home/personal-fund-blank" component={PersonalFundContainer} />
          <Route path="/home/personal-fund" component={PersonalFundContainer} />
          <Route path="/home/personal-fund-detail" component={PersonalFundDetailContainer} />
          
          <Route path="/home/auth-query" component={AuthQueryContainer} />
          <Route path="/home/auth-query-blank" component={AuthQueryBlankContainer} />
          
          <Route path="/home/investor-register" component={InvestorRegisterContainer} />
          <Route path="/home/investor-register-blank" component={InvestorRegisterBlankContainer} />
          <Route path="/home/investor-registered-hint" component={InvestorRegisteredHintContainer} />
          <Route path="/home/risk-evaluation" component={RiskEvaluationDashboardContainer} />

          <Route path="/home/appointment" component={AppointmentContainer} />
          <Route path="/home/appointment-blank" component={AppointmentBlankContainer} />

          <Route path="/home/reservation" component={ReservationContainer} />
          
          <Route path="/home/server-down-notice" component={ServerDownNoticeContainer} />

          <Route path="/home/change-password" component={ChangePasswordContainer} />
        </Switch>
      </div>
    );
  }
}

export default Home;