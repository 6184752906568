const LOCAL_STORAGE_TOKEN_KEY = 'auth-token';
const LOCAL_STORAGE_ROLE_KEY = 'user-role';
const LOCAL_STORAGE_USER_KEY = 'user-name';

class LocalStorageClient {
  constructor() {
    this.useLocalStorage = typeof localStorage !== 'undefined';

    if (this.useLocalStorage) {
      this.token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
      this.role = localStorage.getItem(LOCAL_STORAGE_ROLE_KEY);
      this.userName = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    }
  }

  // 读写token
  setToken(token) {
    this.token = token;

    if (this.useLocalStorage) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    }
  }

  getToken() {
    return this.token;
  }

  removeToken() {
    this.token = null;

    if (this.useLocalStorage) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    }
  }

  // 读写role
  setRole(role) {
    this.role = role;

    if (this.useLocalStorage) {
      localStorage.setItem(LOCAL_STORAGE_ROLE_KEY, role);
    }
  }

  getRole() {
    return this.role;
  }

  removeRole() {
    this.role = null;

    if (this.useLocalStorage) {
      localStorage.removeItem(LOCAL_STORAGE_ROLE_KEY);
    }
  }

  // 读写userName
  setUserName(userName) {
    this.userName = userName;

    if (this.useLocalStorage) {
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, userName);
    }
  }

  getUserName() {
    return this.userName;
  }

  removeUserName() {
    this.userName = null;

    if (this.useLocalStorage) {
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    }
  }

  isLoggedIn() {
    return !!this.token;
  }

  get(key) {
    if (this.useLocalStorage) {
      const item = localStorage.getItem(key);
      return JSON.parse(item);
    }
  }

  save(key, value) {
    if (this.useLocalStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  saveGridColumnState(key, state) {
    if (this.useLocalStorage) {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }

  getGridColumnState(key) {
    if (this.useLocalStorage) {
      const item = localStorage.getItem(key);
      return JSON.parse(item);
    }
  }
}

export const localStorageClient = new LocalStorageClient();
