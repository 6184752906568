import React, {Component} from 'react';
import { ListView, Button, Modal, ActivityIndicator } from 'antd-mobile';
import { Helmet } from "react-helmet";
import './FundProduct.css';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

class FundProductList extends Component {

  constructor(props) {
    super(props);

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      dataSource,
      isLoading: true,
      indicatorLoading: true,
      noticeModalVisible: false,
    };
  }

  componentDidUpdate() {
    const {
      fundProductDataBlob,
      loadFundProductsActionStatus,
    } = this.props;

    // 查询基金产品信息
    if (loadFundProductsActionStatus === ACTION_STATUS.SUCCESS) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(fundProductDataBlob),
        isLoading: false,
      });

      this.props.initLoadFundProductsActionStatus();

      this.setState({
        indicatorLoading: false
      });
    }

    if (loadFundProductsActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadFundProductsActionStatus();

      this.setState({
        indicatorLoading: false
      });
    }

  }

  componentDidMount() {
    this.props.loadFundProducts();
  }

  viewFundDetailBtnOnClicked = (fund) => {
    this.props.history.push({
      pathname: '/home/fund-detail', 
      state: {
        fund
      }
    });
  }

  noticeBarOnClicked = () => {
    this.setState({
      noticeModalVisible: true,
    });
  }

  noticeModalOnClose = () => {
    this.setState({
      noticeModalVisible: false,
    });
  }

  render() {
    const {
      dataSource,
      indicatorLoading,
    } = this.state;

    const {
      fundProductList,
    } = this.props;

    let index = 0;
    const row = (rowData, sectionID, rowID) => {
      if (index >= fundProductList.length) {
        index = 0;
      }
      const obj = fundProductList[index++];
      return (
        <div key={rowID} className="fundProductRowItem">
          <div className="fundProductRowTitleBox">
            <div className="fundProductTitleRedBox" />
            <div className="fundProductTitleTitle">{ obj.fundName }</div>
          </div>
          <div className="fundProductValueBox">
            <div className="totalReturnBox">
              <span className="totalReturnValue">{ `${obj.itdReturn}%` }</span>
              <span className="totalReturnLabel">累计收益({obj.fundAreaType==='1'?'费前':'费后'})</span>
            </div>
            <div className="fundProductValueRow2Box">
              <div className="priceBox">
                <span className="priceValue">{obj.fundName.toLowerCase().includes('copoint')?'-':obj.price}</span>
                <span className="priceLabel">{obj.fundAreaType==='1'?'费前':'费后'}{ `单位净值(更新日期：${obj.priceDateLabel})` }</span>
              </div>
              <div className="monthReturnBox">
                <span className="monthReturnValue">{ `${obj.mtdReturn}%` }</span>
                <span className="monthReturnLabel">月涨幅</span>
              </div>
            </div>
            <div className="viewDetailBtnBox">
              <Button size="small" 
                style={{ backgroundColor: '#1970b6', color: 'white', fontSize: '10px' }}
                onClick={this.viewFundDetailBtnOnClicked.bind(this, obj)}
              >查看详情</Button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="fundProductBox">
        <Helmet>
          <meta charSet="utf-8" />
          <title>保银投资客户服务</title>
        </Helmet>
        <Modal
          visible={this.state.noticeModalVisible}
          transparent
          maskClosable={false}
          onClose={this.noticeModalOnClose}
          title="保银公告"
          footer={[{ 
            text: '知道了...', 
            onPress: () => this.noticeModalOnClose() 
          }]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div>
          {/** 即日起本页面不再提供公告，直接在页面上标注 费前 费后
           * 本平台公布的国内及海外基金均为费后收益，显示的总份额已扣减了预计提的表现费（托管行还未扣除），敬请查询！*/}
          </div>
        </Modal>

        <ActivityIndicator
          toast
          text="Loading..."
          animating={indicatorLoading}
        />

        <ListView 
          className="am-list"
          ref={el => this.lv = el}
          dataSource={dataSource}
          renderRow={row}
          // renderSeparator={separator}
          useBodyScroll
          pageSize={1}
        />
      </div>
    );
  }
}

export default FundProductList;