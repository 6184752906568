import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
    // change password
    START_WX_CHANGE_PASSWORD,
    WX_CHANGE_PASSWORD_SUCCESS,
    WX_CHANGE_PASSWORD_FAILURE,
    INIT_WX_CHANGE_PASSWORD_ACTION_STATUS,

} from './changePasswordConstants';


const initialState = {
}


// change password
function startWxChangePassword(state) {
    return {
        ...state,
        wxChangePasswordActionStatus: ACTION_STATUS.LOGINING,
    };
}
function wxChangePasswordSuccess(state, resp) {
    return {
        ...state,
        wxChangePasswordActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function wxChangePasswordFailure(state, err) {
    return {
        ...state,
        wxChangePasswordActionStatus: ACTION_STATUS.ERROR,
        wxChangePasswordActionResp: err,
    };
}
function initWxChangePasswordActionStatus(state) {
    return {
        ...state,
        wxChangePasswordActionStatus: ACTION_STATUS.READY,
    };
}


export default createReducer(initialState, {
    // change password
    [START_WX_CHANGE_PASSWORD]: startWxChangePassword,
    [WX_CHANGE_PASSWORD_SUCCESS]: wxChangePasswordSuccess,
    [WX_CHANGE_PASSWORD_FAILURE]: wxChangePasswordFailure,
    [INIT_WX_CHANGE_PASSWORD_ACTION_STATUS]: initWxChangePasswordActionStatus,

  
});
