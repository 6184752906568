import {connect} from 'react-redux';
import PersonalFundList from '../components/PersonalFundList';

import {
  // Load Personal Fund
  loadPersonalFund,
  initLoadPersonalFundActionStatus,
} from '../personalFundActions';

const mapStateToProps = state => {
  return {
    // Load Personal Fund
    loadPersonalFundActionStatus: state.personalFund.loadPersonalFundActionStatus,
    personalFundList: state.personalFund.personalFundList,
    personalFundDataBlob: state.personalFund.personalFundDataBlob,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Personal Fund
    loadPersonalFund: (userGuid) => dispatch(loadPersonalFund(userGuid)),
    initLoadPersonalFundActionStatus: () => dispatch(initLoadPersonalFundActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalFundList);