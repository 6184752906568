// Load WX Auth Info
export const START_LOAD_WX_AUTH_INFO = 'START_LOAD_WX_AUTH_INFO';
export const LOAD_WX_AUTH_INFO_SUCCESS = 'LOAD_WX_AUTH_INFO_SUCCESS';
export const LOAD_WX_AUTH_INFO_FAILURE = 'LOAD_WX_AUTH_INFO_FAILURE';
export const INIT_LOAD_WX_AUTH_INFO_ACTION_STATUS = 'INIT_LOAD_WX_AUTH_INFO_ACTION_STATUS';

// Load UserInfo By OpenId
export const START_LOAD_USERINFO = 'START_LOAD_USERINFO';
export const LOAD_USERINFO_SUCCESS = 'LOAD_USERINFO_SUCCESS';
export const LOAD_USERINFO_FAILURE = 'LOAD_USERINFO_FAILURE';
export const INIT_LOAD_USERINFO_ACTION_STATUS = 'INIT_LOAD_USERINFO_ACTION_STATUS';

// Auth query blank failed
export const SET_AUTH_QUERY_BLANK_FAILED = 'SET_AUTH_QUERY_BLANK_FAILED';
export const RESET_AUTH_QUERY_BLANK_STATUS = 'RESET_AUTH_QUERY_BLANK_STATUS';

