// Load Fund Basic Info
export const START_LOAD_FUND_BASIC_INFO = 'START_LOAD_FUND_BASIC_INFO';
export const LOAD_FUND_BASIC_INFO_SUCCESS = 'LOAD_FUND_BASIC_INFO_SUCCESS';
export const LOAD_FUND_BASIC_INFO_FAILURE = 'LOAD_FUND_BASIC_INFO_FAILURE';
export const INIT_LOAD_FUND_BASIC_INFO_ACTION_STATUS = 'INIT_LOAD_FUND_BASIC_INFO_ACTION_STATUS';

// Load Fund Values
export const START_LOAD_FUND_VALUES = 'START_LOAD_FUND_VALUES';
export const LOAD_FUND_VALUES_SUCCESS = 'LOAD_FUND_VALUES_SUCCESS';
export const LOAD_FUND_VALUES_FAILURE = 'LOAD_FUND_VALUES_FAILURE';
export const INIT_LOAD_FUND_VALUES_ACTION_STATUS = 'INIT_LOAD_FUND_VALUES_ACTION_STATUS';