import {
  // Load WX Auth Info
  START_LOAD_WX_AUTH_INFO,
  LOAD_WX_AUTH_INFO_SUCCESS,
  LOAD_WX_AUTH_INFO_FAILURE,
  INIT_LOAD_WX_AUTH_INFO_ACTION_STATUS,

  // Load UserInfo By OpenId
  START_LOAD_USERINFO,
  LOAD_USERINFO_SUCCESS,
  LOAD_USERINFO_FAILURE,
  INIT_LOAD_USERINFO_ACTION_STATUS,

  // Auth query blank failed
  SET_AUTH_QUERY_BLANK_FAILED,
  RESET_AUTH_QUERY_BLANK_STATUS,

} from './homeConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

// Load WX Auth Info
function startLoadWXAuthInfo() {
  return {
    type: START_LOAD_WX_AUTH_INFO
  };
}

export function loadWXAuthInfo(code) {
  const requestParams = {
    code,
  };

  return function(dispatch) {
    dispatch(startLoadWXAuthInfo());
    httpClient
        .get(UrlConfig.LOAD_WX_AUTH_INFO, requestParams)
        .then(resp => dispatch(loadWXAuthInfoSuccess(resp)))
        .catch(err => dispatch(loadWXAuthInfoFailure(err)));
  };
}

function loadWXAuthInfoSuccess(resp) {
  return {
    type: LOAD_WX_AUTH_INFO_SUCCESS,
    payload: resp
  };
}

function loadWXAuthInfoFailure(err) {
  return {
    type: LOAD_WX_AUTH_INFO_FAILURE,
    payload: err
  };
}

export function initLoadWXAuthInfoActionStatus() {
  return {
    type: INIT_LOAD_WX_AUTH_INFO_ACTION_STATUS
  };
}

// Load UserInfo By OpenId
function startLoadUserInfo() {
  return {
    type: START_LOAD_USERINFO
  };
}

export function loadUserInfo(openId) {
  const requestParams = {
    'open_id': openId,
  };

  return function(dispatch) {
    dispatch(startLoadUserInfo());
    httpClient
      .get(UrlConfig.LOAD_WX_USERINFO, requestParams)
      .then(resp => dispatch(loadUserInfoSuccess(resp)))
      .catch(err => dispatch(loadUserInfoFailure(err)));
  };
}

function loadUserInfoSuccess(resp) {
  return {
    type: LOAD_USERINFO_SUCCESS,
    payload: resp
  };
}

function loadUserInfoFailure(err) {
  return {
    type: LOAD_USERINFO_FAILURE,
    payload: err
  };
}

export function initLoadUserInfoActionStatus() {
  return {
    type: INIT_LOAD_USERINFO_ACTION_STATUS,
  };
}


// Auth query blank failed
export function setAuthQueryBlankFailed(errMsg) {
  return {
    type: SET_AUTH_QUERY_BLANK_FAILED,
    payload: errMsg,
  };
}
export function resetAuthQueryBlankStatus() {
  return {
    type: RESET_AUTH_QUERY_BLANK_STATUS,
  };
}