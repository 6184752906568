import {connect} from 'react-redux';
import Reservation from '../components/Reservation';

import {
  // Make Appointment
  makeAppointment,
  initMakeAppointmentActionStatus,
} from '../reservationActions';

import {
  // Load Fund Products
  loadFundProducts,
  initLoadFundProductsActionStatus,
} from '../../fundProduct/fundProductActions';

const mapStateToProps = state => {
  return {
    // Make Appointment
    makeAppointmentActionStatus: state.appointment.makeAppointmentActionStatus,

    // Load Fund Products Action Status
    loadFundProductsActionStatus: state.fundProduct.loadFundProductsActionStatus,
    fundProductList: state.fundProduct.fundProductList,

    // Fund Select Options
    fundOptions: state.appointment.fundOptions,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Make Appointment
    makeAppointment: (appointment) => dispatch(makeAppointment(appointment)),
    initMakeAppointmentActionStatus: () => dispatch(initMakeAppointmentActionStatus()),

    // Load Fund Products
    loadFundProducts: () => dispatch(loadFundProducts()),
    initLoadFundProductsActionStatus: () => dispatch(initLoadFundProductsActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reservation);