import {connect} from 'react-redux';
import InvestorRegisteredHint from '../components/InvestorRegisteredHint';

const mapStateToProps = state => {
  return {

  };
} 

const mapDispatchToProps = dispatch => {
  return {

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestorRegisteredHint);