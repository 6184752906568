import {connect} from 'react-redux';
import RiskEvaluationDashboard from '../components/RiskEvaluationDashboard';

import {
  // Load Risk Evaluations
  loadRiskEvaluations,
  initLoadRiskEvaluationsActionStatus,

} from '../investorRegisterActions';

const mapStateToProps = state => {
  return {
    // Load Risk Evaluations
    loadRiskEvaluationsActionStatus: state.investorRegister.loadRiskEvaluationsActionStatus,
    riskEvaluations: state.investorRegister.riskEvaluations,
    personalRiskEvaluations: state.investorRegister.personalRiskEvaluations,
    orgRiskEvaluations: state.investorRegister.orgRiskEvaluations,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
     // Load Risk Evaluations
    loadRiskEvaluations: () => dispatch(loadRiskEvaluations()),
    initLoadRiskEvaluationsActionStatus: () => dispatch(initLoadRiskEvaluationsActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskEvaluationDashboard);