import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import HomeContainer from '../../views/features/home/containers/HomeContainer';

import './App.css';
import 'antd-mobile/dist/antd-mobile.css';
import 'antd/dist/antd.css';

class App extends Component {
  render() {
    return (
      <div className="box">
        <Switch>
          <Route path="/home" component={HomeContainer} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
