import {
  // Load Fund Products
  START_LOAD_FUND_PRODUCTS,
  LOAD_FUND_PRODUCTS_SUCCESS,
  LOAD_FUND_PRODUCTS_FAILURE,
  INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS,

} from './fundProductConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// Load Fund Products
function startLoadFundProducts() {
  return {
    type: START_LOAD_FUND_PRODUCTS
  };
}

export function loadFundProducts() {
  return function(dispatch) {
    dispatch(startLoadFundProducts());
    httpClient
      .get(UrlConfig.LOAD_FUND_PRODUCTS)
      .then(resp => dispatch(loadFundProductsSuccess(resp)))
      .catch(err => dispatch(loadFundProductsFailure(err)));
  };
}

function loadFundProductsSuccess(resp) {
  return {
    type: LOAD_FUND_PRODUCTS_SUCCESS,
    payload: resp
  };
}

function loadFundProductsFailure(err) {
  return {
    type: LOAD_FUND_PRODUCTS_FAILURE,
    payload: err
  };
}

export function initLoadFundProductsActionStatus() {
  return {
    type: INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS
  };
}
