import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
  // Send Captcha
  START_SEND_CAPTCHA,
  SEND_CAPTCHA_SUCCESS,
  SEND_CAPTCHA_FAILURE,
  INIT_SEND_CAPTCHA_ACTION_STATUS,

  // Auth Captcha
  START_AUTH_CAPTCHA,
  AUTH_CAPTCHA_SUCCESS,
  AUTH_CAPTCHA_FAILURE,
  INIT_AUTH_CAPTCHA_ACTION_STATUS,

  // auth password
  START_WX_CHECK_PASSWORD,
  WX_CHECK_PASSWORD_SUCCESS,
  WX_CHECK_PASSWORD_FAILURE,
  INIT_WX_CHECK_PASSWORD_ACTION_STATUS,



  
    ///////////
    START_IS_ERROR_USER,
    IS_ERROR_USER_SUCCESS,
    IS_ERROR_USER_FAILURE,
    INIT_IS_ERROR_USER_ACTION_STATUS,




} from './authQueryConstants';


const initialState = {
  // Send Captcha
  sendCaptchaActionStatus: ACTION_STATUS.READY,
  authToken: '',

  // Auth Captcha
  authCaptchaActionStatus: ACTION_STATUS.READY,
}

// Send Captcha
function startSendCaptcha(state) {
  return {
    ...state,
    sendCaptchaActionStatus: ACTION_STATUS.LOGINING,
  };
}

function sendCaptchaSuccess(state, resp) {

  return {
    ...state,
    sendCaptchaActionStatus: ACTION_STATUS.SUCCESS,
    authToken: resp.resultData.authToken,
  };
}

function sendCaptchaFailure(state, err) {
  return {
    ...state,
    sendCaptchaActionStatus: ACTION_STATUS.ERROR,
  };
}

function initSendCaptchaActionStatus(state) {
  return {
    ...state,
    sendCaptchaActionStatus: ACTION_STATUS.READY,
  };
}

// Auth Captcha
function startAuthCaptcha(state) {
  return {
    ...state,
    authCaptchaActionStatus: ACTION_STATUS.LOGINING,
  };
}

function authCaptchaSuccess(state, resp) {
  return {
    ...state,
    authCaptchaActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function authCaptchaFailure(state, err) {
  return {
    ...state,
    authCaptchaActionStatus: ACTION_STATUS.ERROR,
  };
}

function initAuthCaptchaActionStatus(state) {
  return {
    ...state,
    authCaptchaActionStatus: ACTION_STATUS.READY,
  };
}

// auth password
function startWxCheckPassword(state) {
  return {
    ...state,
    wxCheckPasswordActionStatus: ACTION_STATUS.LOGINING,
  };
}
function wxCheckPasswordSuccess(state, resp) {
  return {
    ...state,
    wxCheckPasswordActionStatus: ACTION_STATUS.SUCCESS,
  };
}
function wxCheckPasswordFailure(state, err) {
  return {
    ...state,
    wxCheckPasswordActionStatus: ACTION_STATUS.ERROR,
  };
}
function initWxCheckPasswordActionStatus(state) {
  return {
    ...state,
    wxCheckPasswordActionStatus: ACTION_STATUS.READY,
  };
}


////////////////
function startIsErrorUser(state) {
  return {
    ...state,
    isErrorUserStatus: ACTION_STATUS.LOGINING,
  };
}
function isErrorUserSuccess(state, resp) {
  return {
    ...state,
    isErrorUserStatus: ACTION_STATUS.SUCCESS,
    isErroruserResult: false,
  };
}
function isErrorUserFailure(state, err) {
  return {
    ...state,
    isErrorUserStatus: ACTION_STATUS.ERROR,
    isErroruserResult: true,
  };
}
function initIsErrorUserActionStatus(state) {
  return {
    ...state,
    isErrorUserStatus: ACTION_STATUS.READY,
  };
}



export default createReducer(initialState, {
  // Send Captcha
  [START_SEND_CAPTCHA]: startSendCaptcha,
  [SEND_CAPTCHA_SUCCESS]: sendCaptchaSuccess,
  [SEND_CAPTCHA_FAILURE]: sendCaptchaFailure,
  [INIT_SEND_CAPTCHA_ACTION_STATUS]: initSendCaptchaActionStatus,

  // Auth Captcha
  [START_AUTH_CAPTCHA]: startAuthCaptcha,
  [AUTH_CAPTCHA_SUCCESS]: authCaptchaSuccess,
  [AUTH_CAPTCHA_FAILURE]: authCaptchaFailure,
  [INIT_AUTH_CAPTCHA_ACTION_STATUS]: initAuthCaptchaActionStatus,

  // auth password
  [START_WX_CHECK_PASSWORD]: startWxCheckPassword,
  [WX_CHECK_PASSWORD_SUCCESS]: wxCheckPasswordSuccess,
  [WX_CHECK_PASSWORD_FAILURE]: wxCheckPasswordFailure,
  [INIT_WX_CHECK_PASSWORD_ACTION_STATUS]: initWxCheckPasswordActionStatus,


  // 
  [START_IS_ERROR_USER]: startIsErrorUser,
  [IS_ERROR_USER_SUCCESS]: isErrorUserSuccess,
  [IS_ERROR_USER_FAILURE]: isErrorUserFailure,
  [INIT_IS_ERROR_USER_ACTION_STATUS]: initIsErrorUserActionStatus,

});