// Load Personal Fund Basic Info
export const START_LOAD_PERSONAL_FUND_BASIC_INFO = 'START_LOAD_PERSONAL_FUND_BASIC_INFO';
export const LOAD_PERSONAL_FUND_BASIC_INFO_SUCCESS = 'LOAD_PERSONAL_FUND_BASIC_INFO_SUCCESS';
export const LOAD_PERSONAL_FUND_BASIC_INFO_FAILURE = 'LOAD_PERSONAL_FUND_BASIC_INFO_FAILURE';
export const INIT_LOAD_PERSONAL_FUND_BASIC_INFO_ACTION_STATUS = 'INIT_LOAD_PERSONAL_FUND_BASIC_INFO_ACTION_STATUS';

// Load Personal Fund Values
export const START_LOAD_PERSONAL_FUND_VALUES = 'START_LOAD_PERSONAL_FUND_VALUES';
export const LOAD_PERSONAL_FUND_VALUES_SUCCESS = 'LOAD_PERSONAL_FUND_VALUES_SUCCESS';
export const LOAD_PERSONAL_FUND_VALUES_FAILURE = 'LOAD_PERSONAL_FUND_VALUES_FAILURE';
export const INIT_LOAD_PERSONAL_FUND_VALUES_ACTION_STATUS = 'INIT_LOAD_PERSONAL_FUND_VALUES_ACTION_STATUS';

// Load Personal Trade Record
export const START_LOAD_PERSONAL_TRADE_RECORD = 'START_LOAD_PERSONAL_TRADE_RECORD';
export const LOAD_PERSONAL_TRADE_RECORD_SUCCESS = 'LOAD_PERSONAL_TRADE_RECORD_SUCCESS';
export const LOAD_PERSONAL_TRADE_RECORD_FAILURE = 'LOAD_PERSONAL_TRADE_RECORD_FAILURE';
export const INIT_LOAD_PERSONAL_TRADE_RECORD_ACTION_STATUS = 'INIT_LOAD_PERSONAL_TRADE_RECORD_ACTION_STATUS';