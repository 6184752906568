import {connect} from 'react-redux';
import ServerDownNotice from '../components/ServerDownNotice';

import {

} from '../systemNoticeActions';



const mapStateToProps = state => {
  return {
    
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServerDownNotice);