import { combineReducers } from 'redux';

import homeReducer from '../../views/features/home/homeReducer';
import fundProductReducer from '../../views/features/fundProduct/fundProductReducer';
import fundDetailReducer from '../../views/features/fundDetail/fundDetailReducer';
import personalFundReducer from '../../views/features/personalFund/personalFundReducer';
import personalFundDetailReducer from '../../views/features/personalFundDetail/personalFundDetailReducer';
import investorRegisterReducer from '../../views/features/investorRegister/investorRegisterReducer';
import authQueryReducer from '../../views/features/authQuery/authQueryReducer';
import appointmentReducer from '../../views/features/appointment/appointmentReducer';
import changePasswordReducer from '../../views/features/authQuery/changePasswordReducer';

const rootReducer = combineReducers({
  home: homeReducer,
  fundProduct: fundProductReducer,
  fundDetail: fundDetailReducer,
  personalFund: personalFundReducer,
  personalFundDetail: personalFundDetailReducer,
  investorRegister: investorRegisterReducer,
  authQuery: authQueryReducer,
  appointment: appointmentReducer,
  changePassword: changePasswordReducer,
});

export default rootReducer;