import React, {Component} from 'react';
import { ListView, Button, NoticeBar, Modal, Popover } from 'antd-mobile';
import { Helmet } from "react-helmet";
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import './PersonalFund.css';

class PersonalFundList extends Component {

  constructor(props) {
    super(props);

    const getSectionData = (dataBlob, sectionID) => dataBlob[sectionID];
    const getRowData = (dataBlob, sectionID, rowID) => dataBlob[rowID];

    const dataSource = new ListView.DataSource({
      getRowData,
      getSectionHeaderData: getSectionData,
      rowHasChanged: (row1, row2) => row1 !== row2,
      sectionHeaderHasChanged: (s1, s2) => s1 !== s2,
    });

    this.state = {
      dataSource,
      isLoading: true,
      openId: props.history.location.state.openId,
      userGuid: props.history.location.state.userGuid,
      userName: props.history.location.state.userName,
      noticeModalVisible: false,
      popoverVisible: false,
    };
  }

  componentDidMount() {
    const openId = this.state.openId;
    const userGuid = this.state.userGuid;

    this.props.loadPersonalFund(userGuid);
  }

  componentDidUpdate() {
    const {
      personalFundDataBlob,
      personalFundList,
      loadPersonalFundActionStatus,
    } = this.props;

    if (loadPersonalFundActionStatus === ACTION_STATUS.SUCCESS) {
      const dataBlobs = {};
      let sectionIDs = [];
      let rowIDs = [];

      const investorGuidArray = [];
      personalFundList.forEach(personalFund => {
        if (!investorGuidArray.includes(personalFund.investorGuid)) {
          investorGuidArray.push(personalFund.investorGuid);
        }
      });

      for (let i = 0; i < investorGuidArray.length; i++) {
        const investorGuid = investorGuidArray[i];
        const filterPersonalFundDataByInvestor = personalFundList.filter(fundData => fundData.investorGuid === investorGuid);

        const personalFundData = filterPersonalFundDataByInvestor[0];

        const sectionName = personalFundData.investorName;

        sectionIDs.push(sectionName);
        dataBlobs[sectionName] = sectionName;
        rowIDs[i] = [];
        
        for (let j = 0; j < filterPersonalFundDataByInvestor.length; j++) {
          const rowGuid = filterPersonalFundDataByInvestor[j].guid;
          rowIDs[i].push(rowGuid);
          dataBlobs[rowGuid] = filterPersonalFundDataByInvestor[j];
        }
      }

      sectionIDs = [...sectionIDs];
      rowIDs = [...rowIDs];

      this.setState({
        dataSource: this.state.dataSource.cloneWithRowsAndSections(dataBlobs, sectionIDs, rowIDs),
        isLoading: false,
      });

      this.props.initLoadPersonalFundActionStatus();
    }
  }

  viewPersonalFundDetailBtnOnClicked = (personalFund) => {
    this.props.history.push({
      pathname: '/home/personal-fund-detail', 
      state: {
        personalFund
      }
    });
  }

  noticeBarOnClicked = () => {
    this.setState({
      noticeModalVisible: true,
    });
  }

  noticeModalOnClose = () => {
    this.setState({
      noticeModalVisible: false,
    });
  }


  popoverOnclick = () => {
    this.setState({
      ...this.state,
      popoverVisible: false,
    });
  }


  render() {
    const {
      dataSource,
      userName,
      noticeModalVisible,
    } = this.state;

    const {
      loadPersonalFundActionStatus,
      personalFundList,
    } = this.props;

    const row = (rowData, sectionID, rowID) => {
      const obj = rowData;
      return (
        <div key={rowID} className="personalFundRowItem">
          <div className="personalFundProductRowTitleBox">
            <div className="personalFundTitleRedBox" />
            <span className="personalFundTitle">{ obj.fundTitle }</span>
          </div>
          <div className="personalFundValueBox">
            <div className="personalFundTotalReturnBox">
              <span className="personalFundTotalReturnValue">{ obj.totalAmount }</span>
              <span className="personalFundTotalReturnLabel">{ obj.currencyLabel }</span>
              <span className="personalFundTotalReturnTipLabel">( 费后市值 )</span>
            </div>
            <div className="personalFundValueRow2Box">
              <div className="personalFundPriceBox">
                <span className="personalFundPriceValue">{obj.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : obj.price}</span>
                {/* <span className="personalFundPriceLabel">{obj.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '单位净值(更新日期：不适用)' : `单位净值(更新日期：${obj.priceDateLabel})` }</span> */}
                <span className="personalFundPriceLabel">{obj.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? `(市值更新日期：${obj.priceDateLabel})` : `单位净值(更新日期：${obj.priceDateLabel})` }</span>
              </div>
              <div className="personalFundMonthReturnBox">
                <span className="personalFundMonthReturnValue">{ obj.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : obj.totalShare }</span>
                {/* <span className="personalFundMonthReturnLabel">份额(份)</span> */}
                <span className="personalFundMonthReturnLabel">{ obj.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '基金为合伙人制,无单位净值' : '份额(份)' }</span>
              </div>
            </div>
            <div className="personalFundViewDetailBtnBox">
            <Button size="small" 
                style={{ backgroundColor: '#1970b6', color: 'white', fontSize: '10px' }}
                onClick={this.viewPersonalFundDetailBtnOnClicked.bind(this, obj)}
              >查看详情</Button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="personalFundBox">

        <Popover mask
            visible={this.state.popoverVisible}
            overlay={[
              (
                <span>
                    <h2 align="center">关于警惕冒用Pinpoint名义进行网络诈骗的提示</h2>
                    <div><br/>尊敬的投资人：</div>
                    <div><br/>近期我们发现有不法分子冒用Pinpoint的名义发送含有钓鱼网址的电子邮件。</div>
                    <div><br/>如果您近期收到提及Pinpoint申购收款账号更改的邮件，请注意！ 这是一封包含错误域名：sscincc.com的网络钓鱼电子邮件。</div>
                    <div><br/>Pinpoint旗下海外以及国内基金的申购收款账号为基金合同、申购表上的账号，目前没有做过任何改变。如果付款明细有任何变化，我们均会在基金合同、申购表上体现并且公告说明。</div>
                    <div><br/>如有任何疑问，您可通过微信公众号后台留言、发送邮件至 marketing@pinpointfund.com / poppy.law@pinpointfund.com 或拨打 021-61682268 / 852-31922330 随时与我们联系，谢谢。</div>
                    <div align="center"><br/>
                      <Button size="small" 
                          type="primary"
                          inline
                          size="small"
                          onClick={this.popoverOnclick}
                      >确&nbsp;&nbsp;定</Button>
                    </div>
                </span>
              ),
            ]}
            align={{
              overflow: { adjustY: -1, adjustX: -1 },
              offset: [0, 0],
            }}
          >
            <div></div>
        </Popover>


        <Helmet>
          <meta charSet="utf-8" />
          <title>基金净值</title>
        </Helmet>
        <NoticeBar 
          onClick={this.noticeBarOnClicked}
          marqueeProps={{ loop: true, fps: 20, style: { padding: '0 7.5px' } }}>
          尊敬的<span>{ userName }</span>，您好。本平台公布的国内及海外基金均为费后收益，显示的总份额已扣减了预计提的表现费（托管行还未扣除），敬请查询！
        </NoticeBar>

        <Modal
          visible={noticeModalVisible}
          transparent
          maskClosable={false}
          onClose={this.noticeModalOnClose}
          footer={[{ 
            text: '知道了...', 
            onPress: () => this.noticeModalOnClose() 
          }]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div>
          尊敬的<span>{ userName }</span>，您好。本平台公布的国内及海外基金均为费后收益，显示的总份额已扣减了预计提的表现费（托管行还未扣除），敬请查询！
          </div>
        </Modal>

        <ListView 
          className="am-list"
          ref={el => this.lv = el}
          dataSource={dataSource}
          renderRow={row}
          useBodyScroll
          pageSize={1}
          renderSectionHeader={sectionData => {
            return (
              <div className="sectionHeader">{sectionData}</div>
            )
          }}
        />
      </div>
    );
  }
}

export default PersonalFundList;