
const CERTIFICATE_TYPE_CODE = {
  IDENTITY_CARD: '0',
  PASSPORT: '1',
  CHINA_HK_MACAO_PASSPORT: '2',
  HK_IDENTITY_CARD: '3',
  HK_MACAO_CHINA_PASSPORT: '4',
  PERMANENT_RESIDENCE_BOOK: '5',
  FOREIGN_PASSPORT: '6',
  BUSINESS_LICENSE: '7',
  ORGANIZATION_CODE_CERTIFICATE: '8',
  MTP: '9',
  OTHERS: '10',
};

const CERTIFICATE_TYPE_LABEL = {
  IDENTITY_CARD: '身份证',
  PASSPORT: '护照',
  CHINA_HK_MACAO_PASSPORT: '中华人民共和国往来港澳通行证',
  HK_IDENTITY_CARD: '香港居民身份证',
  HK_MACAO_CHINA_PASSPORT: '港澳居民来往内地通行证',
  PERMANENT_RESIDENCE_BOOK: '户口本',
  FOREIGN_PASSPORT: '外国护照',
  BUSINESS_LICENSE: '营业执照',
  ORGANIZATION_CODE_CERTIFICATE: '组织机构代码证',
  MTP: '台胞证',
  OTHERS: '其他',
};

export const CERTIFICATE_TYPE_MAP = [
  // 身份证
  {
    code: CERTIFICATE_TYPE_CODE.IDENTITY_CARD,
    label: CERTIFICATE_TYPE_LABEL.IDENTITY_CARD
  },
  // 护照
  {
    code: CERTIFICATE_TYPE_CODE.PASSPORT,
    label: CERTIFICATE_TYPE_LABEL.PASSPORT
  },
  // 中华人民共和国往来港澳通行证
  {
    code: CERTIFICATE_TYPE_CODE.CHINA_HK_MACAO_PASSPORT,
    label: CERTIFICATE_TYPE_LABEL.CHINA_HK_MACAO_PASSPORT
  },
  // 香港居民身份证
  {
    code: CERTIFICATE_TYPE_CODE.HK_IDENTITY_CARD,
    label: CERTIFICATE_TYPE_LABEL.HK_IDENTITY_CARD
  },
  // 港澳居民来往内地通行证
  {
    code: CERTIFICATE_TYPE_CODE.HK_MACAO_CHINA_PASSPORT,
    label: CERTIFICATE_TYPE_LABEL.HK_MACAO_CHINA_PASSPORT
  },
  // 户口本
  {
    code: CERTIFICATE_TYPE_CODE.PERMANENT_RESIDENCE_BOOK,
    label: CERTIFICATE_TYPE_LABEL.PERMANENT_RESIDENCE_BOOK
  },
  // 外国护照
  {
    code: CERTIFICATE_TYPE_CODE.FOREIGN_PASSPORT,
    label: CERTIFICATE_TYPE_LABEL.FOREIGN_PASSPORT
  },
  // 营业执照
  {
    code: CERTIFICATE_TYPE_CODE.BUSINESS_LICENSE,
    label: CERTIFICATE_TYPE_LABEL.BUSINESS_LICENSE
  },
  // 组织机构代码证
  {
    code: CERTIFICATE_TYPE_CODE.ORGANIZATION_CODE_CERTIFICATE,
    label: CERTIFICATE_TYPE_LABEL.ORGANIZATION_CODE_CERTIFICATE
  },
  // 台胞证
  {
    code: CERTIFICATE_TYPE_CODE.MTP,
    label: CERTIFICATE_TYPE_LABEL.MTP
  },
  // 其他
  {
    code: CERTIFICATE_TYPE_CODE.OTHERS,
    label: CERTIFICATE_TYPE_LABEL.OTHERS
  },
];