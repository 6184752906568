import {connect} from 'react-redux';
import ChangePasswordDashboard from '../components/ChangePasswordDashboard';

import {
    // Send Captcha
    sendCaptcha,
    initSendCaptchaActionStatus,
} from '../authQueryActions';


import {
    // change password
    wxChangePassword,
    initWxChangePasswordActionStatus,

} from '../changePasswordActions';


const mapStateToProps = state => {
    return {
        // Send Captcha
        sendCaptchaActionStatus: state.authQuery.sendCaptchaActionStatus,
        authToken: state.authQuery.authToken,

        // change password
        wxChangePasswordActionStatus: state.changePassword.wxChangePasswordActionStatus,
        wxChangePasswordActionResp: state.changePassword.wxChangePasswordActionResp,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // Send Captcha
        sendCaptcha: (openId) => dispatch(sendCaptcha(openId)),
        initSendCaptchaActionStatus: () => dispatch(initSendCaptchaActionStatus()),

        // change password
        wxChangePassword: (params) => dispatch(wxChangePassword(params)),
        initWxChangePasswordActionStatus: () => dispatch(initWxChangePasswordActionStatus()),

    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePasswordDashboard);