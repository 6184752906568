import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
  // Load Fund Products
  START_LOAD_FUND_PRODUCTS,
  LOAD_FUND_PRODUCTS_SUCCESS,
  LOAD_FUND_PRODUCTS_FAILURE,
  INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS,

} from './fundProductConstants';

const initialState = {
  // Load Fund Products Action Status
  loadFundProductsActionStatus: ACTION_STATUS.READY,

  fundProductList: [],
  fundProductDataBlob: {},
}

// Load Fund Products
function startLoadFundProducts(state) {
  return {
    ...state,
    loadFundProductsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadFundProductsSuccess(state, resp) {
  const fundProductList = resp.resultData;
  const length = fundProductList.length;
  const dataBlob = {};

  for (let i = 0; i < length; i++) {
    dataBlob[`${i}`] = `row - ${i}`;
  }

  return {
    ...state,
    loadFundProductsActionStatus: ACTION_STATUS.SUCCESS,
    fundProductList: resp.resultData,
    fundProductDataBlob: dataBlob,
  };
}

function loadFundProductsFailure(state, err) {
  return {
    ...state,
    loadFundProductsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadFundProductsActionStatus(state) {
  return {
    ...state,
    loadFundProductsActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Fund Products
  [START_LOAD_FUND_PRODUCTS]: startLoadFundProducts,
  [LOAD_FUND_PRODUCTS_SUCCESS]: loadFundProductsSuccess,
  [LOAD_FUND_PRODUCTS_FAILURE]: loadFundProductsFailure,
  [INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS]: initLoadFundProductsActionStatus,

});