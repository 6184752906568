// Load Fund Products
export const START_LOAD_FUND_PRODUCTS = 'START_LOAD_FUND_PRODUCTS';
export const LOAD_FUND_PRODUCTS_SUCCESS = 'LOAD_FUND_PRODUCTS_SUCCESS';
export const LOAD_FUND_PRODUCTS_FAILURE = 'LOAD_FUND_PRODUCTS_FAILURE';
export const INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS = 'INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS';

// Load Latest Bulletin
export const START_LOAD_LATEST_BULLETIN = 'START_LOAD_FUND_PRODUCTS';
export const LOAD_LATEST_BULLETIN_SUCCESS = 'LOAD_FUND_PRODUCTS_SUCCESS';
export const LOAD_LATEST_BULLETIN_FAILURE = 'LOAD_FUND_PRODUCTS_FAILURE';
export const INIT_LOAD_LATEST_BULLETIN_ACTION_STATUS = 'INIT_LOAD_FUND_PRODUCTS_ACTION_STATUS';