import React, {Component} from 'react';
import { Button, InputItem, Toast } from 'antd-mobile';

import Select from 'antd/es/select';

import { Helmet } from "react-helmet";
import './Appointment.css';
import { APPOINTMENT_TYPE_MAP } from '../../../../utils/AppointmentTypeUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const Option = Select.Option;

// 预约类型
const AppointmentTypeOptions = [];
for (let i = 0; i < APPOINTMENT_TYPE_MAP.length; i++) {
  AppointmentTypeOptions.push(<Option key={APPOINTMENT_TYPE_MAP[i].code}>{APPOINTMENT_TYPE_MAP[i].label}</Option>);
}

class Appointment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openId: props.history.location.state.openId,
      fundGuid: '',
      fundName: '',
      name: '',
      mobileNo: '',
      appointmentType: "0",
    };
  }

  componentDidMount() {
    this.props.loadFundProducts();
  }

  componentDidUpdate() {
    const {
      makeAppointmentActionStatus,
      loadFundProductsActionStatus,
    } = this.props;

    // Make Appointment
    if (makeAppointmentActionStatus === ACTION_STATUS.SUCCESS) {
      Toast.success("已预约成功", 2);
      this.props.initMakeAppointmentActionStatus();

      this.setState({
        // fundGuid: '',
        // fundName: '',
        name: '',
        mobileNo: '',
        appointmentType: "0",
      });

      
    }

    if (makeAppointmentActionStatus === ACTION_STATUS.ERROR) {
      Toast.fail("预约失败了", 2);
      this.props.initMakeAppointmentActionStatus();
    }

    if (loadFundProductsActionStatus === ACTION_STATUS.SUCCESS || loadFundProductsActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadFundProductsActionStatus();
    }
  }

  // 基金产品选择
  fundProductSelectOnChanged = (value) => {
    const fundProductList = this.props.fundProductList;
    const fundGuid = value;
    const fundNameArray = fundProductList.filter(fund => fundGuid === fund.fundGuid).map(fund => fund.fundName);
    const fundName = fundNameArray[0];

    this.setState({
      fundGuid,
      fundName,
    });
  }

  nameInputOnChanged = (value) => {
    this.setState({
      name: value,
    });
  }

  mobileNoInputOnChanged = (value) => {
    this.setState({
      mobileNo: value,
    });
  }

  appointmentTypeSelectOnChanged = (value) => {
    this.setState({
      appointmentType: value,
    });
  }

  // 确定预约
  confirmBtnOnClicked = () => {
    const {
      openId,
      fundGuid,
      fundName,
      name,
      mobileNo,
      appointmentType,
    } = this.state;

    if (!!!fundGuid) {
      Toast.fail("请选择基金产品", 2);
      return;
    }

    if (!!!name) {
      Toast.fail("请输入您的姓名", 2);
      return;
    }

    if (!!!mobileNo) {
      Toast.fail("请输入您的手机号", 2);
      return;
    }

    const appointment = {
      openId,
      fundGuid,
      fundName,
      name,
      mobileNo,
      appointmentType,
    };

    this.props.makeAppointment(appointment);

  }

  render() {
    const {
      fundGuid,
      name,
      mobileNo,
      appointmentType,
    } = this.state;

    const {
      fundOptions,
    } = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>申赎预约</title>
        </Helmet>
        <div className="appointmentFormBox">
          {/* 选择基金产品 */}
          <Select
            style={{ width: '100%', marginTop: 10 }}
            size="large"
            placeholder="请选择基金产品"
            onChange={this.fundProductSelectOnChanged} >
            {fundOptions}
          </Select>
          <InputItem 
            value={name}
            className="appointmentFormInputItem"
            placeholder="请输入您的姓名" 
            onChange={this.nameInputOnChanged}/>
          <InputItem 
            value={mobileNo}
            placeholder="请输入您的手机号" 
            className="appointmentFormInputItem" 
            onChange={this.mobileNoInputOnChanged} />
          {/* 选择预约类型 */}
          <Select
            style={{ width: '100%', marginTop: 10 }}
            size="large"
            placeholder="请选择预约类型"
            value={appointmentType}
            onChange={this.appointmentTypeSelectOnChanged} >
            {AppointmentTypeOptions}
          </Select>
          <Button 
            style={{ backgroundColor: '#1970b6', color: 'white' }}
            className="confirmBtn"
            onClick={this.confirmBtnOnClicked}
          >确定</Button>
        </div>
      </div>
    );
  }
}

export default Appointment;