
// Load Fund Products
const LOAD_FUND_PRODUCTS = '/value/fund-values-list';

// Load Fund Basic Info
const LOAD_FUND_BASIC_INFO = '/fund/fund-basic-info';

// Load Fund Values History
const LOAD_FUND_VALUES_HISTORY = '/value/fund-values-history';

// Load Personal Fund
const LOAD_PERSONAL_FUND = '/investor-net-value/wx-investor-value-summary';

// Load Personal Trade Record
const LOAD_PERSONAL_TRADE_RECORD = '/member/user-trade-record';

// Load Wx UserInfo
const LOAD_WX_USERINFO = '/member/wx-user-info';

// Auth User Asset Query
const AUTH_USER_ASSET_QUERY = '/member/auth-wx-user-asset-query';

// Send WX Auth Query Captcha
const SEND_WX_AUTH_QUERY_CAPTCHA = '/captcha/wx-auth-query-captcha';

// WX User Register
const REGISTER_WX_USER = '/member/new-wx-user';

// WX Auth Info
const LOAD_WX_AUTH_INFO = '/wx-public/wx-auth-info';

// Make Appointment
const MAKE_APPOINTMENT = '/appointment/wx-appointment';

// Load Latest Bulletin
const LOAD_LATEST_BULLETIN = '/bulletin/latest-bulletin';

// Send WX User Register Captcha
const SEND_WX_USER_REGISTER_CAPTCHA = '/captcha/wx-user-register-captcha';

// Load Risk Evaluations
const LOAD_RISK_EVALUATIONS = '/risk-evaluation/risk-evaluations';

// auth/change password
const WX_CHECK_PASSWORD = '/member/wx-check-password';
const WX_CHANGE_PASSWORD = '/member/wx-change-password';


// IS_ERROR_USER
const IS_ERROR_USER = '/err-investor-cert-no/is-error-user';



const UrlConfig = {
  // Load Fund Products
  LOAD_FUND_PRODUCTS,

  // Load Fund Basic Info
  LOAD_FUND_BASIC_INFO,

  // Load Fund Values History
  LOAD_FUND_VALUES_HISTORY,

  // Load Personal Fund
  LOAD_PERSONAL_FUND,

  // Load Personal Trade Record
  LOAD_PERSONAL_TRADE_RECORD,

  // Load Wx UserInfo
  LOAD_WX_USERINFO,

  // Auth User Asset Query
  AUTH_USER_ASSET_QUERY,

  // Send WX Auth Query Captcha
  SEND_WX_AUTH_QUERY_CAPTCHA,

  // WX User Register
  REGISTER_WX_USER,

  // WX Auth Info
  LOAD_WX_AUTH_INFO,

  // Make Appointment
  MAKE_APPOINTMENT,

  // Load Latest Bulletin
  LOAD_LATEST_BULLETIN,

  // Send WX User Register Captcha
  SEND_WX_USER_REGISTER_CAPTCHA,

  // Load Risk Evaluations
  LOAD_RISK_EVALUATIONS,

  // auth/change password
  WX_CHECK_PASSWORD,
  WX_CHANGE_PASSWORD,



  // IS_ERROR_USER
  IS_ERROR_USER,

}

export default UrlConfig;
