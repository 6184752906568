import React, {Component} from 'react';
import { SegmentedControl } from 'antd-mobile';
import ReactList from 'react-list';
import { Helmet } from "react-helmet";
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import FundValueTable from './FundValueTable';

import './FundDetail.css';

class FundDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 1,
      fundProduct: props.history.location.state.fund,
      fundBasicInfo: props.fundBasicInfo,
    };
  }

  componentDidMount() {
    console.log(this.props.history.location.state);

    const fundGuid = this.state.fundProduct.fundGuid;

    this.props.loadFundBasicInfo(fundGuid);
    this.props.loadFundValues(fundGuid);

    
  }

  componentDidUpdate() {
    const {
      loadFundBasicInfoActionStatus,
      fundBasicInfo,
      loadFundValuesActionStatus,
    } = this.props;

    if (loadFundBasicInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.setState({
        fundBasicInfo,
      });

      this.props.initLoadFundBasicInfoActionStatus();
    }
  }

  renderItem(index, key) {
    return <div key={key} className="basicInfoRowItemBox">
      <div className="basicInfoField">{this.state.fundBasicInfo[index].field}</div>
      <div className="basicInfoLabel">{this.state.fundBasicInfo[index].label}</div>
    </div>;
  }

  segmentedControlOnValueChanged = (value) => {
    if (value === '基金概述') {
      this.setState({
        tabIndex: 0
      });
    }

    if (value === '产品净值') {
      this.setState({
        tabIndex: 1
      });
    }
  }

  render() {
    const {
      tabIndex,
      fundProduct,
      fundBasicInfo,
    } = this.state;

    const {
      fundValueList
    } = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>基金详情</title>
        </Helmet>
        <div className="fundDetailBox">
          <div className="fundDetailRowTitleBox">
            <div className="fundDetailTitleRedBox" />
            <div className="fundDetailTitle">{ fundProduct.fundName }</div>
          </div>
          <div className="fundDetailValueBox">
            <div className="detailTotalReturnBox">
              <span className="detailTotalReturnValue">{ `${fundProduct.itdReturn}%` }</span>
              <span className="detailTotalReturnLabel">累计收益({fundProduct.fundAreaType==='1'?'费前':'费后'})</span>
            </div>
            <div className="fundDetailValueRow2Box">
              <div className="detailPriceBox">
                <span className="detailPriceValue">{ fundProduct.price }</span>
                <span className="detailPriceLabel">{fundProduct.fundAreaType==='1'?'费前':'费后'}{ `单位净值(更新日期：${fundProduct.priceDateLabel})` }</span>
              </div>
              <div className="detailMonthReturnBox">
                <span className="detailMonthReturnValue">{ `${fundProduct.mtdReturn}%` }</span>
                <span className="detailMonthReturnLabel">月涨幅</span>
              </div>
            </div>
          </div>
        </div>
        <div className="segmentedControlBox">
          <SegmentedControl 
            selectedIndex={tabIndex} 
            onValueChange={this.segmentedControlOnValueChanged}
            tintColor={'#1970b6'}
            values={['基金概述', '产品净值']} />
        </div>
        {/* 基金概述 */}
        {
          tabIndex === 0 && 
          <div className="fundDetailBasicInfo">
            <span className="basicInfoTitle">基本信息</span>
            <div className="basicInfoListBox">
              <ReactList
                itemRenderer={this.renderItem.bind(this)}
                length={fundBasicInfo.length}
                type='uniform'
              />
            </div>
          </div>
        }
        {/* 产品净值 */}
        {
          tabIndex === 1 && 
          <div className="fundValueBox">
            {/* <FundValueGrid {...this.props} /> */}
            <FundValueTable {...this.props} />
          </div>
        }
      </div>
    );
  }
}

export default FundDetail;