import {
  // Load Personal Fund Basic Info
  START_LOAD_PERSONAL_FUND_BASIC_INFO,
  LOAD_PERSONAL_FUND_BASIC_INFO_SUCCESS,
  LOAD_PERSONAL_FUND_BASIC_INFO_FAILURE,
  INIT_LOAD_PERSONAL_FUND_BASIC_INFO_ACTION_STATUS,

  // Load Personal Fund Values
  START_LOAD_PERSONAL_FUND_VALUES,
  LOAD_PERSONAL_FUND_VALUES_SUCCESS,
  LOAD_PERSONAL_FUND_VALUES_FAILURE,
  INIT_LOAD_PERSONAL_FUND_VALUES_ACTION_STATUS,

  // Load Personal Trade Record
  START_LOAD_PERSONAL_TRADE_RECORD,
  LOAD_PERSONAL_TRADE_RECORD_SUCCESS,
  LOAD_PERSONAL_TRADE_RECORD_FAILURE,
  INIT_LOAD_PERSONAL_TRADE_RECORD_ACTION_STATUS,

} from './personalFundDetailConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

// Load Personal Fund Basic Info
function startLoadPersonalFundBasicInfo() {
  return {
    type: START_LOAD_PERSONAL_FUND_BASIC_INFO
  };
}

export function loadPersonalFundBasicInfo(fundGuid) {
  const requestParams = {
    "fund_guid": fundGuid,
  };

  return function(dispatch) {
    dispatch(startLoadPersonalFundBasicInfo());
    httpClient
      .get(UrlConfig.LOAD_FUND_BASIC_INFO, requestParams)
      .then(resp => dispatch(loadPersonalFundBasicInfoSuccess(resp)))
      .catch(err => dispatch(loadPersonalFundBasicInfoFailure(err)));
  };
}

function loadPersonalFundBasicInfoSuccess(resp) {
  return {
    type: LOAD_PERSONAL_FUND_BASIC_INFO_SUCCESS,
    payload: resp
  };
}

function loadPersonalFundBasicInfoFailure(err) {
  return {
    type: LOAD_PERSONAL_FUND_BASIC_INFO_FAILURE,
    payload: err
  };
}

export function initLoadPersonalFundBasicInfoActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_FUND_BASIC_INFO_ACTION_STATUS
  };
}

// Load Personal Fund Values
function startLoadPersonalFundValues() {
  return {
    type: START_LOAD_PERSONAL_FUND_VALUES
  };
}

export function loadPersonalFundValues({entityGuid, priceDate}) {

  const requestParams = {
    "entity_guid": entityGuid,
    "price_date": priceDate,
    "page_index": 1,
    "page_size": 10000,
  };

  return function(dispatch) {
    dispatch(startLoadPersonalFundValues());
    httpClient
      .get(UrlConfig.LOAD_FUND_VALUES_HISTORY, requestParams)
      .then(resp => dispatch(loadPersonalFundValuesSuccess(resp)))
      .catch(err => dispatch(loadPersonalFundValuesFailure(err)));
  };
}

function loadPersonalFundValuesSuccess(resp) {
  return {
    type: LOAD_PERSONAL_FUND_VALUES_SUCCESS,
    payload: resp
  };
}

function loadPersonalFundValuesFailure(err) {
  return {
    type: LOAD_PERSONAL_FUND_VALUES_FAILURE,
    payload: err
  };
}

export function initLoadPersonalFundValuesActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_FUND_VALUES_ACTION_STATUS
  };
}

// Load Personal Trade Record
function startLoadPersonalTradeRecord() {
  return {
    type: START_LOAD_PERSONAL_TRADE_RECORD
  };
}

export function loadPersonalTradeRecord(investorGuid, entityGuid) {
  const requestParams = {
    "investor_guid": investorGuid,
    "entity_guid": entityGuid,
  };

  return function(dispatch) {
    dispatch(startLoadPersonalTradeRecord());
    httpClient
      .get(UrlConfig.LOAD_PERSONAL_TRADE_RECORD, requestParams)
      .then(resp => dispatch(loadPersonalTradeRecordSuccess(resp)))
      .catch(err => dispatch(loadPersonalTradeRecordFailure(err)));
  };
}

function loadPersonalTradeRecordSuccess(resp) {
  return {
    type: LOAD_PERSONAL_TRADE_RECORD_SUCCESS,
    payload: resp
  };
}

function loadPersonalTradeRecordFailure(err) {
  return {
    type: LOAD_PERSONAL_TRADE_RECORD_FAILURE,
    payload: err
  };
}

export function initLoadPersonalTradeRecordActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_TRADE_RECORD_ACTION_STATUS
  };
}