import React, {Component} from 'react';
import { Result } from 'antd';

import { Helmet } from "react-helmet";
import './SystemNotice.css';

class ServerDownNotice extends Component {

  render() {
    return (
      <div className="notice-box">
        <Helmet>
          <meta charSet="utf-8" />
          <title>通知</title>
        </Helmet>
        <div className="notice-content-top"></div>
        <div className="notice-content">
          <Result
            status="500"
            title="系统维护，请稍后查询"
            subTitle=""
          />
        </div>
        <div className="notice-content"></div>
      </div>
    );
  }
}

export default ServerDownNotice;