import React, { Component } from 'react';
import { ActivityIndicator } from 'antd-mobile';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import './FundDetail.css';

class FundValueTable extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const {
      fundValueList,
      className = 'grid-wrapper',
      loadFundValuesActionStatus
    } = this.props;

    const isLoading = loadFundValuesActionStatus === ACTION_STATUS.LOGINING;
    if (loadFundValuesActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadFundValuesActionStatus();
    }

    return (
      <div className={`${className}`}>
        {isLoading ? (
          <ActivityIndicator
            toast
            text="Loading..."
            animating={isLoading}
          />
          ) : (
          <table>
            <thead>
              <tr>
                <td>日期</td>
                <td>净值</td>
                <td>月涨幅%</td>
                <td>年涨幅%</td>
                <td>累计涨幅%</td>
              </tr>
            </thead>
            <tbody>
              {fundValueList.map(
                (fundValue, i) => (
                    <tr key={i}>
                      <td>{fundValue.priceDateLabel}</td>
                      <td>{fundValue.price}</td>
                      <td>{fundValue.mtdReturn}</td>
                      <td>{fundValue.ytdReturn}</td>
                      <td>{fundValue.itdReturn}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default FundValueTable;