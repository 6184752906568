import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from '../../../utils/GridColumnMap';

import {
  // Load Fund Basic Info
  START_LOAD_FUND_BASIC_INFO,
  LOAD_FUND_BASIC_INFO_SUCCESS,
  LOAD_FUND_BASIC_INFO_FAILURE,
  INIT_LOAD_FUND_BASIC_INFO_ACTION_STATUS,

  // Load Fund Values
  START_LOAD_FUND_VALUES,
  LOAD_FUND_VALUES_SUCCESS,
  LOAD_FUND_VALUES_FAILURE,
  INIT_LOAD_FUND_VALUES_ACTION_STATUS,

} from './fundDetailConstants';


const initialState = {
  fundValueGridColumns: GridColumnMap.fundValueGridColumns,

  // Load Fund Basic Info Action Status
  loadFundBasicInfoActionStatus: ACTION_STATUS.READY,
  fundBasicInfo: [
    {
      "field": "成立日期：",
      "label": "",
    },
    {
      "field": "风险特征：",
      "label": "",
    },
    {
      "field": "投资范围：",
      "label": "",
    },
    // {
    //   "field": "投资策略：",
    //   "label": "",
    // },
    {
      "field": "管理费：",
      "label": "",
    },
    {
      "field": "表现费：",
      "label": "",
    },
    {
      "field": "是否有高水位：",
      "label": "",
    },
    {
      "field": "估值&申购&赎回：",
      "label": "",
    },
    {
      "field": "最少初始申购额度：",
      "label": "",
    },
    {
      "field": "最少追加申购额度：",
      "label": "",
    },
    {
      "field": "管理人：",
      "label": "",
    },
    {
      "field": "托管人：",
      "label": "",
    },
  ],

  // Load Fund Values Action Status
  loadFundValuesActionStatus: ACTION_STATUS.READY,
  fundValueList: [],

}


// Load Fund Product Basic Info
function startLoadFundBasicInfo(state) {
  return {
    ...state,
    loadFundBasicInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadFundBasicInfoSuccess(state, resp) {
  const fundInfo = resp.resultData;
  let fundBasicInfo = initialState.fundBasicInfo;

  if (!!fundInfo) {
    fundBasicInfo = [
      {
        "field": "成立日期：",
        "label": fundInfo.establishedTimeLabel,
      },
      {
        "field": "风险特征：",
        "label": fundInfo.riskFeature,
      },
      {
        "field": "投资范围：",
        "label": fundInfo.investScope,
      },
      // {
      //   "field": "投资策略：",
      //   "label": fundInfo.investStrategy,
      // },
      {
        "field": "管理费：",
        "label": fundInfo.managementFeeLabel,
      },
      {
        "field": "表现费：",
        "label": fundInfo.performanceFeeLabel,
      },
      {
        "field": "是否有高水位：",
        "label": fundInfo.highWaterLevelLabel,
      },
      {
        "field": "估值&申购&赎回：",
        "label": fundInfo.operateRule,
      },
      {
        "field": "最少初始申购额度：",
        "label": fundInfo.leastFirstPurchaseLimit,
      },
      {
        "field": "最少追加申购额度：",
        "label": fundInfo.leastAdditionPurchaseLimit,
      },
      {
        "field": "管理人：",
        "label": fundInfo.administrator,
      },
      {
        "field": "托管人：",
        "label": fundInfo.custodian,
      },
    ];
  }

  return {
    ...state,
    loadFundBasicInfoActionStatus: ACTION_STATUS.SUCCESS,
    fundBasicInfo,
  };
}

function loadFundBasicInfoFailure(state, err) {
  return {
    ...state,
    loadFundBasicInfoActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadFundBasicInfoActionStatus(state) {
  return {
    ...state,
    loadFundBasicInfoActionStatus: ACTION_STATUS.READY,
  };
}


// Load Fund Values
function startLoadFundValues(state) {
  return {
    ...state,
    loadFundValuesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadFundValuesSuccess(state, resp) {
  return {
    ...state,
    loadFundValuesActionStatus: ACTION_STATUS.SUCCESS,
    fundValueList: resp.resultData.data,
  };
}

function loadFundValuesFailure(state, err) {
  return {
    ...state,
    loadFundValuesActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadFundValuesActionStatus(state) {
  return {
    ...state,
    loadFundValuesActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Fund Basic Info
  [START_LOAD_FUND_BASIC_INFO]: startLoadFundBasicInfo,
  [LOAD_FUND_BASIC_INFO_SUCCESS]: loadFundBasicInfoSuccess,
  [LOAD_FUND_BASIC_INFO_FAILURE]: loadFundBasicInfoFailure,
  [INIT_LOAD_FUND_BASIC_INFO_ACTION_STATUS]: initLoadFundBasicInfoActionStatus,

  // Load Fund Values
  [START_LOAD_FUND_VALUES]: startLoadFundValues,
  [LOAD_FUND_VALUES_SUCCESS]: loadFundValuesSuccess,
  [LOAD_FUND_VALUES_FAILURE]: loadFundValuesFailure,
  [INIT_LOAD_FUND_VALUES_ACTION_STATUS]: initLoadFundValuesActionStatus,

});