import React, { Component } from 'react';
import { ActivityIndicator } from 'antd-mobile';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

class FundValueGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const {
      personalFundValueList,
      className = 'grid-wrapper',
      loadPersonalFundValuesActionStatus
    } = this.props;

    const isLoading = loadPersonalFundValuesActionStatus === ACTION_STATUS.LOGINING;
    if (loadPersonalFundValuesActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadPersonalFundValuesActionStatus();
    }

    return (
      <div className={`${className}`}>
        {isLoading ? (
            <ActivityIndicator
              toast
              text="Loading..."
              animating={isLoading}
            />
          ) : (
          <table>
            <thead>
              <tr>
                <td>日期</td>
                <td>净值</td>
                <td>月涨幅%</td>
                <td>年涨幅%</td>
                <td>累计涨幅%</td>
              </tr>
            </thead>
            <tbody>
              {personalFundValueList.map(
                (fundValue, i) => (
                    <tr key={i}>
                      <td>{fundValue.priceDateLabel}</td>
                      <td>{fundValue.fundGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : fundValue.price}</td>
                      <td>{fundValue.mtdReturn}</td>
                      <td>{fundValue.ytdReturn}</td>
                      <td>{fundValue.itdReturn}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default FundValueGrid;