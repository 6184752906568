import {
  // Load Fund Basic Info
  START_LOAD_FUND_BASIC_INFO,
  LOAD_FUND_BASIC_INFO_SUCCESS,
  LOAD_FUND_BASIC_INFO_FAILURE,
  INIT_LOAD_FUND_BASIC_INFO_ACTION_STATUS,

  // Load Fund Values
  START_LOAD_FUND_VALUES,
  LOAD_FUND_VALUES_SUCCESS,
  LOAD_FUND_VALUES_FAILURE,
  INIT_LOAD_FUND_VALUES_ACTION_STATUS,

} from './fundDetailConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// Load Fund Product Basic Info
function startLoadFundBasicInfo() {
  return {
    type: START_LOAD_FUND_BASIC_INFO
  };
}

export function loadFundBasicInfo(fundGuid) {
  const requestParams = {
    "fund_guid": fundGuid,
  };

  return function(dispatch) {
    dispatch(startLoadFundBasicInfo());
    httpClient
      .get(UrlConfig.LOAD_FUND_BASIC_INFO, requestParams)
      .then(resp => dispatch(loadFundBasicInfoSuccess(resp)))
      .catch(err => dispatch(loadFundBasicInfoFailure(err)));
  };
}

function loadFundBasicInfoSuccess(resp) {
  return {
    type: LOAD_FUND_BASIC_INFO_SUCCESS,
    payload: resp
  };
}

function loadFundBasicInfoFailure(err) {
  return {
    type: LOAD_FUND_BASIC_INFO_FAILURE,
    payload: err
  };
}

export function initLoadFundBasicInfoActionStatus() {
  return {
    type: INIT_LOAD_FUND_BASIC_INFO_ACTION_STATUS
  };
}

// Load Fund Values
function startLoadFundValues() {
  return {
    type: START_LOAD_FUND_VALUES
  };
}

export function loadFundValues(entityGuid) {
  const requestParams = {
    "entity_guid": entityGuid,
    "page_index": 1,
    "page_size": 10000,
  };

  return function(dispatch) {
    dispatch(startLoadFundValues());
    httpClient
      .get(UrlConfig.LOAD_FUND_VALUES_HISTORY, requestParams)
      .then(resp => dispatch(loadFundValuesSuccess(resp)))
      .catch(err => dispatch(loadFundValuesFailure(err)));
  };
}

function loadFundValuesSuccess(resp) {
  return {
    type: LOAD_FUND_VALUES_SUCCESS,
    payload: resp
  };
}

function loadFundValuesFailure(err) {
  return {
    type: LOAD_FUND_VALUES_FAILURE,
    payload: err
  };
}

export function initLoadFundValuesActionStatus() {
  return {
    type: INIT_LOAD_FUND_VALUES_ACTION_STATUS
  };
}