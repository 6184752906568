// Send Captcha
export const START_SEND_CAPTCHA = 'START_SEND_CAPTCHA';
export const SEND_CAPTCHA_SUCCESS = 'SEND_CAPTCHA_SUCCESS';
export const SEND_CAPTCHA_FAILURE = 'SEND_CAPTCHA_FAILURE';
export const INIT_SEND_CAPTCHA_ACTION_STATUS = 'INIT_SEND_CAPTCHA_ACTION_STATUS';

// Auth Captcha
export const START_AUTH_CAPTCHA = 'START_AUTH_CAPTCHA';
export const AUTH_CAPTCHA_SUCCESS = 'AUTH_CAPTCHA_SUCCESS';
export const AUTH_CAPTCHA_FAILURE = 'AUTH_CAPTCHA_FAILURE';
export const INIT_AUTH_CAPTCHA_ACTION_STATUS = 'INIT_AUTH_CAPTCHA_ACTION_STATUS';

// auth password
export const START_WX_CHECK_PASSWORD = 'START_WX_CHECK_PASSWORD';
export const WX_CHECK_PASSWORD_SUCCESS = 'WX_CHECK_PASSWORD_SUCCESS';
export const WX_CHECK_PASSWORD_FAILURE = 'WX_CHECK_PASSWORD_FAILURE';
export const INIT_WX_CHECK_PASSWORD_ACTION_STATUS = 'INIT_WX_CHECK_PASSWORD_ACTION_STATUS';





///////////
export const START_IS_ERROR_USER = 'START_IS_ERROR_USER';
export const IS_ERROR_USER_SUCCESS = 'IS_ERROR_USER_SUCCESS';
export const IS_ERROR_USER_FAILURE = 'IS_ERROR_USER_FAILURE';
export const INIT_IS_ERROR_USER_ACTION_STATUS = 'INIT_IS_ERROR_USER_ACTION_STATUS';


