import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
  // Load Personal Fund
  START_LOAD_PERSONAL_FUND,
  LOAD_PERSONAL_FUND_SUCCESS,
  LOAD_PERSONAL_FUND_FAILURE,
  INIT_LOAD_PERSONAL_FUND_ACTION_STATUS,

} from './personalFundConstants';


const initialState = {
  // Load Personal Fund Action Status
  loadPersonalFundActionStatus: ACTION_STATUS.READY,

  personalFundList: [],
  personalFundDataBlob: {},
}


// Load Personal Fund
function startLoadPersonalFund(state) {
  return {
    ...state,
    loadPersonalFundActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalFundSuccess(state, resp) {
  const personalFundList = resp.resultData;
  const length = personalFundList.length;
  const dataBlob = {};

  for (let i = 0; i < length; i++) {
    dataBlob[`${i}`] = `row - ${i}`;
  }

  return {
    ...state,
    loadPersonalFundActionStatus: ACTION_STATUS.SUCCESS,
    personalFundList,
    personalFundDataBlob: dataBlob,
  };
}

function loadPersonalFundFailure(state, err) {
  return {
    ...state,
    loadPersonalFundActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPersonalFundActionStatus(state) {
  return {
    ...state,
    loadPersonalFundActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Personal Fund
  [START_LOAD_PERSONAL_FUND]: startLoadPersonalFund,
  [LOAD_PERSONAL_FUND_SUCCESS]: loadPersonalFundSuccess,
  [LOAD_PERSONAL_FUND_FAILURE]: loadPersonalFundFailure,
  [INIT_LOAD_PERSONAL_FUND_ACTION_STATUS]: initLoadPersonalFundActionStatus,
  
});