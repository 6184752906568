import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import WXAuthUtil from '../../../../utils/WXAuthUtil';
import qs from 'qs';

class AppointmentBlank extends Component {

  componentWillMount() {
    const searchString = window.location.search;
    
    if (searchString === '') {
      window.location.href = WXAuthUtil.APPOINTMENT_AUTH_URL;
    }
  }
  
  componentDidMount() {
    const searchString = window.location.search;

    // searchString 不为空
    if (!!searchString) {
      const searchObj = qs.parse(searchString, { ignoreQueryPrefix: true });
      const code = searchObj.code;
      this.props.loadWXAuthInfo(code);
    }
  }

  componentDidUpdate() {
    const {
      loadWXAuthInfoActionStatus,
      wxAuthInfo,

      // UserInfo By OpenId
      loadUserInfoActionStatus,
      loadUserInfoResult,
    } = this.props;

    // 查询基金授权信息
    if (loadWXAuthInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadWXAuthInfoActionStatus();

      if (wxAuthInfo.hasOwnProperty('errcode')) {
        window.location.href = WXAuthUtil.APPOINTMENT_AUTH_URL;
        return;
      }

      const openId = wxAuthInfo.openid;
      this.props.loadUserInfo(openId);
    }
    
    if (loadWXAuthInfoActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadWXAuthInfoActionStatus();
    }

    // 查询微信openId关联的用户信息
    if (loadUserInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadUserInfoActionStatus();

      const openId = wxAuthInfo.openid;
      this.props.history.replace({
        pathname: '/home/appointment', 
        state: {
          openId,
        }
      });
    }
    
    if (loadUserInfoActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadUserInfoActionStatus();

      const openId = wxAuthInfo.openid;
      this.props.history.replace({
        pathname: '/home/risk-evaluation', 
        state: {
          openId,
        }
      });
    }

  }

  render() {

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>保银投资客户服务</title>
        </Helmet>
      </div>
    );
  }
}

export default AppointmentBlank;