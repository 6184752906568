import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from '../../../utils/GridColumnMap';

import {
  // Load Personal Fund Basic Info
  START_LOAD_PERSONAL_FUND_BASIC_INFO,
  LOAD_PERSONAL_FUND_BASIC_INFO_SUCCESS,
  LOAD_PERSONAL_FUND_BASIC_INFO_FAILURE,
  INIT_LOAD_PERSONAL_FUND_BASIC_INFO_ACTION_STATUS,

  // Load Personal Fund Values
  START_LOAD_PERSONAL_FUND_VALUES,
  LOAD_PERSONAL_FUND_VALUES_SUCCESS,
  LOAD_PERSONAL_FUND_VALUES_FAILURE,
  INIT_LOAD_PERSONAL_FUND_VALUES_ACTION_STATUS,

  // Load Personal Trade Record
  START_LOAD_PERSONAL_TRADE_RECORD,
  LOAD_PERSONAL_TRADE_RECORD_SUCCESS,
  LOAD_PERSONAL_TRADE_RECORD_FAILURE,
  INIT_LOAD_PERSONAL_TRADE_RECORD_ACTION_STATUS,

} from './personalFundDetailConstants';


const initialState = {
  fundValueGridColumns: GridColumnMap.fundValueGridColumns,
  tradeRecordGridColumns: GridColumnMap.tradeRecordGridColumns,

  // Load Personal Fund Basic Info
  loadPersonalFundBasicInfoActionStatus: ACTION_STATUS.READY,
  personalFundBasicInfo: [
    {
      "field": "成立日期：",
      "label": "",
    },
    {
      "field": "风险特征：",
      "label": "",
    },
    {
      "field": "投资范围：",
      "label": "",
    },
    // {
    //   "field": "投资策略：",
    //   "label": "",
    // },
    {
      "field": "管理费：",
      "label": "",
    },
    {
      "field": "表现费：",
      "label": "",
    },
    {
      "field": "是否有高水位：",
      "label": "",
    },
    {
      "field": "估值&申购&赎回：",
      "label": "",
    },
    {
      "field": "最少初始申购额度：",
      "label": "",
    },
    {
      "field": "最少追加申购额度：",
      "label": "",
    },
    {
      "field": "管理人：",
      "label": "",
    },
    {
      "field": "托管人：",
      "label": "",
    },
  ],

  // Load Personal Fund Values
  loadPersonalFundValuesActionStatus: ACTION_STATUS.READY,
  personalFundValueList: [],

  // Load Personal Trade Record
  loadPersonalTradeRecordActionStatus: ACTION_STATUS.READY,
  personalTradeRecordList: [],
}


// Load Personal Fund Basic Info
function startLoadPersonalFundBasicInfo(state) {
  return {
    ...state,
    loadPersonalFundBasicInfoActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalFundBasicInfoSuccess(state, resp) {
  const personalFundInfo = resp.resultData;
  let personalFundBasicInfo = initialState.personalFundBasicInfo;

  if (!!personalFundInfo) {
    personalFundBasicInfo = [
      {
        "field": "成立日期：",
        "label": personalFundInfo.establishedTimeLabel,
      },
      {
        "field": "风险特征：",
        "label": personalFundInfo.riskFeature,
      },
      {
        "field": "投资范围：",
        "label": personalFundInfo.investScope,
      },
      // {
      //   "field": "投资策略：",
      //   "label": personalFundInfo.investStrategy,
      // },
      {
        "field": "管理费：",
        "label": personalFundInfo.managementFeeLabel,
      },
      {
        "field": "表现费：",
        "label": personalFundInfo.performanceFeeLabel,
      },
      {
        "field": "是否有高水位：",
        "label": personalFundInfo.highWaterLevelLabel,
      },
      {
        "field": "估值&申购&赎回：",
        "label": personalFundInfo.operateRule,
      },
      {
        "field": "最少初始申购额度：",
        "label": personalFundInfo.leastFirstPurchaseLimit,
      },
      {
        "field": "最少追加申购额度：",
        "label": personalFundInfo.leastAdditionPurchaseLimit,
      },
      {
        "field": "管理人：",
        "label": personalFundInfo.administrator,
      },
      {
        "field": "托管人：",
        "label": personalFundInfo.custodian,
      },
    ];
  }

  return {
    ...state,
    loadPersonalFundBasicInfoActionStatus: ACTION_STATUS.SUCCESS,
    personalFundBasicInfo,
  };
}

function loadPersonalFundBasicInfoFailure(state, err) {
  return {
    ...state,
    loadPersonalFundBasicInfoActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPersonalFundBasicInfoActionStatus(state) {
  return {
    ...state,
    loadPersonalFundBasicInfoActionStatus: ACTION_STATUS.READY,
  };
}

// Load Personal Fund Values
function startLoadPersonalFundValues(state) {
  return {
    ...state,
    loadPersonalFundValuesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalFundValuesSuccess(state, resp) {
  return {
    ...state,
    loadPersonalFundValuesActionStatus: ACTION_STATUS.SUCCESS,
    personalFundValueList: resp.resultData.data,
  };
}

function loadPersonalFundValuesFailure(state, err) {
  return {
    ...state,
    loadPersonalFundValuesActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPersonalFundValuesActionStatus(state) {
  return {
    ...state,
    loadPersonalFundValuesActionStatus: ACTION_STATUS.READY,
  };
}

// Load Personal Trade Record
function startLoadPersonalTradeRecord(state) {
  return {
    ...state,
    loadPersonalTradeRecordActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalTradeRecordSuccess(state, resp) {
  return {
    ...state,
    loadPersonalTradeRecordActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeRecordList: resp.resultData,
  };
}

function loadPersonalTradeRecordFailure(state, err) {
  return {
    ...state,
    loadPersonalTradeRecordActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPersonalTradeRecordActionStatus(state) {
  return {
    ...state,
    loadPersonalTradeRecordActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Personal Fund Basic Info
  [START_LOAD_PERSONAL_FUND_BASIC_INFO]: startLoadPersonalFundBasicInfo,
  [LOAD_PERSONAL_FUND_BASIC_INFO_SUCCESS]: loadPersonalFundBasicInfoSuccess,
  [LOAD_PERSONAL_FUND_BASIC_INFO_FAILURE]: loadPersonalFundBasicInfoFailure,
  [INIT_LOAD_PERSONAL_FUND_BASIC_INFO_ACTION_STATUS]: initLoadPersonalFundBasicInfoActionStatus,

  // Load Personal Fund Values
  [START_LOAD_PERSONAL_FUND_VALUES]: startLoadPersonalFundValues,
  [LOAD_PERSONAL_FUND_VALUES_SUCCESS]: loadPersonalFundValuesSuccess,
  [LOAD_PERSONAL_FUND_VALUES_FAILURE]: loadPersonalFundValuesFailure,
  [INIT_LOAD_PERSONAL_FUND_VALUES_ACTION_STATUS]: initLoadPersonalFundValuesActionStatus,

  // Load Personal Trade Record
  [START_LOAD_PERSONAL_TRADE_RECORD]: startLoadPersonalTradeRecord,
  [LOAD_PERSONAL_TRADE_RECORD_SUCCESS]: loadPersonalTradeRecordSuccess,
  [LOAD_PERSONAL_TRADE_RECORD_FAILURE]: loadPersonalTradeRecordFailure,
  [INIT_LOAD_PERSONAL_TRADE_RECORD_ACTION_STATUS]: initLoadPersonalTradeRecordActionStatus,
});